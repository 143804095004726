import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getStockService = async (account_uuid) => {
  const { data } = await supabase
    .from("site_stock")
    .select()
    .eq("account_uuid", account_uuid)
    .order("id", { ascending: false });
  return await data;
};

export const getStockByUuidService = async (site_uuid) => {
  const { data } = await supabase
    .from("site_stock")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id", { ascending: false });
  return await data;
};

export const setStockService = async (payload) => {
  const { data, error } = await supabase.from("site_stock").insert(payload);
  if (error) {
    return error;
  } else {
    return await data;
  }
};

export const updateStockService = async (payload) => {
  const { data } = await supabase
    .from("site_stock")
    .update(payload)
    .eq("site_uuid", payload.site_uuid);
  return await data;
};

export const deleteStockService = async (id) => {
  const { data } = await supabase.from("site_stock").delete().eq("id", id);
  return await data;
};
