import React, { useState, useEffect } from "react";
import { Layout, Select, Image, Watermark, Table, Tag } from "antd";
import CardMonitor from "../../components/dashboard/CardMonitor";
import { UserSwitchOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import MapDemo from "../../components/dashboard/MapDemoo";
import { getGeoService } from "../../services/geolocation";
import { getLogs } from "../../services/timeattendance";
import moment from "moment";
import Download from "../../components/Download";

const { Content: AntdContent } = Layout;

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const formatter = (value) => <CountUp end={value} separator="," />;
  const [geo, setGeo] = useState([]);
  const [mode, setMode] = useState("1");
  const [companyName, setCompanyName] = useState("");
  const [logs, setLogs] = useState([]);

  const columns = [
    {
      title: "ใบหน้าเข้างาน",
      dataIndex: "employee_image_in",
      key: "employee_image_in",
      render: (_, { employee_image_in }) => (
        <Image
          src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${employee_image_in}`}
          width={50}
          height={50}
        />
      ),
    },
    {
      title: "ใบหน้าออกงาน",
      dataIndex: "employee_image_out",
      key: "employee_image_out",
      render: (_, { employee_image_out }) =>
        employee_image_out ? (
          <Image
            src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${employee_image_out}`}
            width={50}
            height={50}
          />
        ) : (
          <Tag color={"red"}>ยังอยู่ในพื้นที่</Tag>
        ),
    },
    {
      title: "ชื่อ",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "เวลาเข้า",
      dataIndex: "in",
      key: "in",
      render: (_, { in: in_datetime }) => (
        <div>{moment(in_datetime).format("DD/MM/YYYY HH:mm:ss")}</div>
      ),
    },
    {
      title: "เวลาออก",
      dataIndex: "out",
      key: "out",
      render: (_, { out: out_datetime }) => (
        <div>
          {out_datetime ? (
            moment(out_datetime).format("DD/MM/YYYY HH:mm:ss")
          ) : (
            <Tag color={"red"}>ยังอยู่ในพื้นที่</Tag>
          )}
        </div>
      ),
    },
    {
      title: "ระยะเวลา",
      dataIndex: "duration",
      key: "duration",
      render: (_, { in: in_datetime, out: out_datetime }) => (
        <div>
          {out_datetime ? (
            (() => {
              const duration = moment.duration(
                moment(out_datetime).diff(moment(in_datetime))
              );
              const hours = duration.hours();
              const minutes = duration.minutes();
              if (hours === 0 && minutes === 0) return "น้อยกว่า 1 นาที";
              if (hours === 0) return `${minutes} นาที`;
              return `${hours} ชั่วโมง ${minutes} นาที`;
            })()
          ) : (
            <Tag color={"red"}>ยังอยู่ในพื้นที่</Tag>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const result = await getLogs(JSON.parse(localStorage.getItem("site")));
      setLogs(result);
    };
    fetchData();
    setInterval(() => {
      fetchData();
    }, 5 * 1000);
  }, [localStorage.getItem("site")]);

  useEffect(() => {
    setCompanyName(
      JSON.parse(localStorage.getItem("account"))?.company_name ||
        JSON.parse(localStorage.getItem("account"))?.site_name
    );
    const fetchData = async () => {
      const result = await getGeoService(
        JSON.parse(localStorage.getItem("site"))
      );
      if (result.length === 0) {
        setGeo([]);
      } else {
        setGeo(result);
      }
    };
    fetchData();
    setInterval(() => {
      fetchData();
    }, 15 * 1000);
  }, [localStorage.getItem("site")]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="mt-4 grid grid-cols-2 gap-2">
        <CardMonitor
          color={"green"}
          icon={<UserSwitchOutlined />}
          title={
            <div className="flex justify-between">
              <div>รายงานการเข้า-ออกหน่วยงาน</div>
              <div className="w-8 h-8 flex items-center justify-center rounded-[6px] text-black text-[24px]">
                <Download
                  tip={"โหลดรายงานการเข้า-ออกหน่วยงาน"}
                  icon={<CloudDownloadOutlined />}
                  status={"timeattendance"}
                />
              </div>
            </div>
          }
          actions={
            <div className="overflow-y-scroll max-h-[410px] w-full space-y-2 text-left">
              <Watermark
                content={["วัตถุประสงค์เพื่อรักษาความปลอดภัย", companyName]}
                font={{
                  color: "rgba(255, 0, 0, 0.15)",
                }}
              >
                <Table rowKey="id" columns={columns} dataSource={logs} />
              </Watermark>
            </div>
          }
        />
        <CardMonitor
          className="col-span-2"
          title={
            <div className="flex justify-between">
              <div>ตำแหน่งการใช้งาน</div>
              <div>
                <Select
                  className="ml-4"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="ตำแหน่งล่าสุด"
                  onChange={(value) => setMode(value)}
                  value={mode}
                  options={[{ value: "1", label: "ตำแหน่งล่าสุด" }]}
                />
              </div>
            </div>
          }
          actions={
            <div className="w-[100vw]">
              <MapDemo mode={mode} now={geo} />
            </div>
          }
        />
      </div>
    </AntdContent>
  );
};

export default ContentComp;
