import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getPatrolService = async (site_uuid) => {
  const { data } = await supabase
    .from("patrol")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id", { ascending: false });
  return await data;
};

export const getPatrolFilterTodayService = async (site_uuid, report_status) => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 7);
  let isoString = currentDate.toISOString();
  const { data } = await supabase
    .from("patrol")
    .select()
    .eq("site_uuid", site_uuid)
    .eq("report_status", report_status)
    .gte("created_at", isoString.split("T")[0] )
    .order("id", { ascending: false });
  return await data;
};

export const getPatrolFilterAbnormalTodayService = async (site_uuid, report_status) => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 7);
  let isoString = currentDate.toISOString();
  const { data } = await supabase
    .from("patrol")
    .select()
    .eq("site_uuid", site_uuid)
    .eq("report_status", report_status)
    // .gte("created_at", isoString.split("T")[0] )
    .order("id", { ascending: false });
  return await data;
};

export const getPatrolSearchService = async (keyword, site_uuid) => {
  const { data } = await supabase
    .from("patrol")
    .select("*")
    .eq("site_uuid", site_uuid)
    .contains("cls", [keyword]);
  //console.log(data);
  return await data;
};
