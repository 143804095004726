import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import QRCodeReact from "qrcode.react";
import { getCheckpointService } from "../services/checkpoint";
import { getProfile } from "../services/profile";
import { useReactToPrint } from "react-to-print";

function CheckpointGen({ site_uuid }) {
  const [checkpoint, setCheckpoint] = useState([]);
  const [companyLogo, setCompanyLogo] = useState("");
  const componentRef = useRef();

  useEffect(() => {
    getProfile().then((data) => {
      setCompanyLogo("https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/" + data[0].company_logo);
    });
    async function fetchCheckpoints() {
      const checkpoints = await getCheckpointService(site_uuid);
      setCheckpoint(checkpoints);
    }
    fetchCheckpoints();
  }, [site_uuid]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Button onClick={handlePrint}>Print</Button>
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          className="grid grid-cols-3 place-items-center m-[5px]"
        >
          {checkpoint.map((item, index) => (
            <div key={index} className="relative w-[300px] h-[375px] mb-3">
              <img
                src="/patrol.jpg"
                width={300}
                height={360}
                crossorigin="anonymous"
              />
              <div className="absolute top-[15px] right-0 left-0 flex items-center justify-center">
                <img src={companyLogo} height={50} crossorigin="anonymous" />
              </div>
              <div className="absolute top-[95px] right-[65px] flex items-center justify-center">
                <QRCodeReact
                  value={item.checkpoint_uuid}
                  size={170}
                  level={"L"}
                  renderAs="svg"
                />
              </div>
              <div
                style={{
                  left: 0,
                  right: 0,
                  bottom: item.checkpoint_name.length < 45 ? "65px" : "30px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "absolute",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {item.checkpoint_name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CheckpointGen;
