import React from "react";
import { Layout } from "antd";
import Model from "../../components/3d";

const { Content: AntdContent } = Layout;

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="w-[100%] h-[100%] grid grid-cols-1 gap-2">
        <Model />
      </div>
    </AntdContent>
  );
};

export default ContentComp;
