import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "antd";
import {
  getCheckpointService,
  updateCheckpointService,
} from "../services/checkpoint";
import { useDispatch } from "react-redux";
import { setCheckpoint } from "../reducers/checkpointSlice";
import DynamicInputList from "./DynamicInputList";

const CheckpointEdit = ({ payload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_uuid, setSiteUUID] = useState("");
  const [checkpoint_uuid, setCheckpointUUID] = useState("");
  const [checkpoint_name, setCheckpointName] = useState("");
  const [checkpoint_note, setCheckpointNote] = useState("");
  const [checkpoint_lat, setCheckpointLat] = useState("");
  const [checkpoint_long, setCheckpointLong] = useState("");
  const [checkpoint_distance_limit, setCheckpointDistanceLimit] = useState(0);
  const [checkpoint_question, setCheckpointQuestion] = useState([]);

  useEffect(() => {
    setSiteUUID(payload.site_uuid);
    setCheckpointUUID(payload.checkpoint_uuid);
    setCheckpointName(payload.checkpoint_name);
    setCheckpointNote(payload.checkpoint_note);
    setCheckpointLat(payload.checkpoint_lat);
    setCheckpointLong(payload.checkpoint_long);
    setCheckpointDistanceLimit(payload.checkpoint_distance_limit);
    setCheckpointQuestion(payload.checkpoint_question);
  }, [payload]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleValueChange = (value) => {
    setCheckpointQuestion(value);
  };

  const handleCheckpointChange = async () => {
    setLoading(true);
    const data = await getCheckpointService(site_uuid);
    dispatch(setCheckpoint(data));
    setLoading(false);
  };

  const handleUpdateDevice = async () => {
    setLoading(true);

    await updateCheckpointService({
      site_uuid: site_uuid,
      checkpoint_uuid: checkpoint_uuid,
      checkpoint_name: checkpoint_name,
      checkpoint_note: checkpoint_note,
      checkpoint_lat: parseFloat(checkpoint_lat),
      checkpoint_long: parseFloat(checkpoint_long),
      checkpoint_distance_limit: parseInt(checkpoint_distance_limit),
      checkpoint_question: checkpoint_question,
    });
    // await handleCheckpointChange();

    setLoading(false);
    window.location.reload();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleUpdateDevice();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        แก้ไขจุดตรวจ
      </Button>
      <Modal
        title="แก้ไขจุดตรวจ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อจุดตรวจ:</p>
        <Input
          value={checkpoint_name}
          onChange={(e) => {
            setCheckpointName(e.target.value);
          }}
          placeholder="ชื่อจุดตรวจ"
        />
        <p>ตำแหน่ง gps ของจุดตรวจ:</p>
        <div className="flex items-center space-x-2">
          <Input
            value={checkpoint_lat}
            onChange={(e) => {
              setCheckpointLat(e.target.value);
            }}
            placeholder="ละติจูด"
          />
          <Input
            value={checkpoint_long}
            onChange={(e) => {
              setCheckpointLong(e.target.value);
            }}
            placeholder="ลองติจูด"
          />
        </div>
        <p>ระยะทางไม่เกิน:</p>
        <Input
          value={checkpoint_distance_limit}
          onChange={(e) => {
            setCheckpointDistanceLimit(e.target.value);
          }}
          placeholder="ระยะทางไม่เกิน (เมตร)"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={checkpoint_note}
          onChange={(e) => {
            setCheckpointNote(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
        <p>รายการคำถาม:</p>
        <DynamicInputList
          value={checkpoint_question}
          onValueChange={handleValueChange}
        />
      </Modal>
    </>
  );
};
export default CheckpointEdit;
