import React, { useState, useEffect } from "react";
import { Layout, Table, Modal, Button } from "antd";
import TrainingCreate from "../../components/TrainingCreate";
import TrainingEdit from "../../components/TrainingEdit";
import TrainingView from "../../components/TrainingView";
import Delete from "../../components/Delete";
import {
  getTrainingByUuidService,
  deleteTrainingService,
} from "../../services/training";
import moment from "moment";
import Download from "../../components/Download";
import { CloudDownloadOutlined } from "@ant-design/icons";

const { Content: AntdContent } = Layout;

const columns = [
  {
    title: "หัวข้อการอบรม",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "วันที่อบรม",
    dataIndex: "start_date",
    key: "start_date",
    render: (_, payload) => {
      return (
        <div>
          {payload.start_date
            ? moment(payload.start_date).format("DD/MM/YYYY")
            : "ไม่มีข้อมูล"}
        </div>
      );
    },
  },
  {
    title: "เวลาที่อบรม",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "ชื่อผู้อบรม",
    dataIndex: "trainer",
    key: "trainer",
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    width: 300,
    key: "x",
    render: (_, payload) => {
      return (
        <div>
          <TrainingView payload={payload} /> <TrainingEdit payload={payload} />{" "}
          <Delete system={null} func={deleteTrainingService} id={payload.id} />
        </div>
      );
    },
  },
];

const ContentComp = ({ colorBgContainer, borderRadiusLG, site_uuid }) => {
  const [loading, setLoading] = useState(false);
  const [form, setFrom] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(
    !localStorage.getItem("site_youtube")
  );

  const handleModalClose = () => {
    localStorage.setItem("site_youtube", true);
    setIsModalOpen(false);
  };

  const handleEmployeeChange = async () => {
    setLoading(true);
    const data = await getTrainingByUuidService(site_uuid);
    setFrom(data);
    setLoading(false);
  };

  useEffect(() => {
    handleEmployeeChange();
  }, [site_uuid]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">การอบรมพนักงาน</div>
        <div className="flex space-x-2">
          <Button type="primary">
            <Download
              tip={"โหลดเอกสารการอบรม"}
              icon={<CloudDownloadOutlined />}
              status={"training"}
            ></Download>
          </Button>
          <TrainingCreate />
        </div>
      </div>
      {/* <Modal
        title="การอบรมพนักงาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/3ilvSmmXVNk?si=eruTzA92hQgZ0AJj"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}
      <Table className="mt-4" columns={columns} dataSource={form} rowKey="id" />
    </AntdContent>
  );
};

export default ContentComp;
