import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getTrainingService = async (account_uuid) => {
  const { data } = await supabase
    .from("site_training")
    .select()
    .eq("account_uuid", account_uuid)
    .order("id");
  return await data;
};

export const getTrainingByUuidService = async (site_uuid) => {
  const { data } = await supabase
    .from("site_training")
    .select()
    .eq("site_uuid", site_uuid);
  return await data;
};

export const setTrainingService = async (payload) => {
  const { data, error } = await supabase.from("site_training").insert(payload);
  if (error) {
    return error;
  } else {
    return await data;
  }
};

export const updateTrainingService = async (payload) => {
  const { data } = await supabase
    .from("site_training")
    .update(payload)
    .eq("site_uuid", payload.site_uuid);
  return await data;
};

export const deleteTrainingService = async (id) => {
  const { data: deleteData } = await supabase
    .from("site_training")
    .delete()
    .eq("id", id);
  const site_uuid = JSON.parse(localStorage.getItem("site"));
  const { data: list, error: ListError } = await supabase.storage
    .from("siamai")
    .list(`docs/${site_uuid}/training/${id}`);
  const filesToRemove = list.map(
    (x) => `docs/${site_uuid}/training/${id}/${x.name}`
  );

  const { data: removeData, error: RemoveError } = await supabase.storage
    .from("siamai")
    .remove(filesToRemove);
  return await deleteData;
};

export const uploadFileService = async (id, site_uuid, file) => {
  const { data, error } = await supabase.storage
    .from("siamai")
    .upload(`docs/${site_uuid}/training/${id}/${file.newName}`, file);
  if (error) {
    await deleteFileService(id, site_uuid, file.newName);
    const { data, error } = await supabase.storage
      .from("siamai")
      .upload(`docs/${site_uuid}/training/${id}/${file.newName}`, file);
    if (error) {
      return error;
    }
    return await `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/docs/${site_uuid}/training/${id}/${file.newName}`;
  } else {
    return await `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/docs/${site_uuid}/training/${id}/${file.newName}`;
  }
};

export const deleteFileService = async (id, site_uuid, file) => {
  const { data, error } = await supabase.storage
    .from("siamai")
    .remove([`docs/${site_uuid}/training/${id}/${file}`]);
  if (error) {
    return error;
  } else {
    return await data;
  }
};
