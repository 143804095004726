import React, { useState, useEffect } from "react";
import { Layout, Table, Image, Button } from "antd";
import EmployeeCreate from "../../components/EmployeeCreate";
import EmployeeEdit from "../../components/EmployeeEdit";
import EmployeeView from "../../components/EmployeeView";
import Delete from "../../components/Delete";
import {
  getEmployeeByUuidService,
  deleteEmployeeService,
} from "../../services/employee";
import moment from "moment";
import Download from "../../components/Download";
import { CloudDownloadOutlined } from "@ant-design/icons";

const { Content: AntdContent } = Layout;

const columns = [
  {
    title: "วันที่",
    dataIndex: "created_at",
    key: "created_at",
    render: (_, { created_at }) => (
      <div>
        {moment(created_at.replace("+00:00", "")).format("DD/MM/YYYY HH:mm:ss")}
      </div>
    ),
  },
  {
    title: "ชื่อหน่วยงาน",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "เลขบัตรประชาชน",
    dataIndex: "idcard",
    key: "idcard",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "วันเกิด",
    dataIndex: "birthday",
    key: "birthday",
    render: (_, payload) => {
      return (
        <div>
          {payload.birthday
            ? `${moment(payload.birthday).format(
                "DD/MM/YYYY"
              )} (อายุ ${moment().diff(payload.birthday, "years")} ปี)`
            : "ไม่มีข้อมูล"}
        </div>
      );
    },
  },
  {
    title: "ใบอนุญาตเลขที่",
    dataIndex: "license",
    key: "license",
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    width: 300,
    key: "x",
    render: (_, payload) => {
      return (
        <div className="space-x-2">
          <EmployeeView payload={payload} />
          <EmployeeEdit payload={payload} />
          <Delete system={null} func={deleteEmployeeService} id={payload.id} />
        </div>
      );
    },
  },
];

const ContentComp = ({ colorBgContainer, borderRadiusLG, site_uuid }) => {
  const [loading, setLoading] = useState(false);
  const [form, setFrom] = useState([]);

  const handleEmployeeChange = async () => {
    setLoading(true);
    const data = await getEmployeeByUuidService(site_uuid);
    setFrom(data);
    setLoading(false);
  };

  useEffect(() => {
    handleEmployeeChange();
  }, [site_uuid]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายชื่อพนักงานรักษาความปลอดภัย</div>
        <div className="flex space-x-2">
          <Button type="primary">
            <Download
              tip={"โหลดรายชื่อพนักงาน"}
              icon={<CloudDownloadOutlined />}
              status={"employee"}
            ></Download>
          </Button>
          <EmployeeCreate />
        </div>
      </div>
      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                {record.image1 && (
                  <Image
                    width={100}
                    height={100}
                    src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${record.image1}`}
                  />
                )}
                {record.image2 && (
                  <Image
                    width={100}
                    height={100}
                    src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${record.image2}`}
                  />
                )}
                {record.image3 && (
                  <Image
                    width={100}
                    height={100}
                    src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${record.image3}`}
                  />
                )}
              </div>
            </div>
          ),
        }}
        dataSource={form}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;
