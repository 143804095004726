import React, { useState } from "react";
import { Button, Modal, Input, DatePicker } from "antd";
import { setEmployeeService } from "../services/employee";
import moment from "moment";

const EmployeeCreate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    idcard: "",
    birthday: null,
    license: "",
    note: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);

  const handleAddCheckpoint = async () => {
    setLoading(true);

    await setEmployeeService({
      ...formData,
      site_uuid: JSON.parse(localStorage.getItem("site")),
    });

    setLoading(false);
    window.location.reload();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddCheckpoint();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มพนักงาน
      </Button>
      <Modal
        title="เพิ่มรายชื่อพนักงาน"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อ:</p>
        <Input
          value={formData.name}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              name: e.target.value,
            }));
          }}
          placeholder="ชื่อ"
        />
        <p>เลขบัตรประชาชน:</p>
        <div className="flex items-center space-x-2">
          <Input
            value={formData.idcard}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                idcard: e.target.value,
              }));
            }}
            placeholder="เลขบัตรประชาชน"
          />
        </div>

        <p>เบอร์ติดต่อ:</p>
        <div className="flex items-center space-x-2">
          <Input
            value={formData.phone}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                phone: e.target.value,
              }));
            }}
            placeholder="เบอร์ติดต่อ"
          />
        </div>

        <p>วัน/เดือน/ปีเกิด :</p>
        <DatePicker
          format="DD/MM/YYYY"
          value={formData.birthday ? moment(new Date(formData.birthday)) : null}
          onChange={(date, dateString) =>
            setFormData((prevState) => ({
              ...prevState,
              birthday: date,
            }))
          }
          placeholder="วัน/เดือน/ปีเกิด"
        />

        <p>ใบอนุญาตเลขที่ :</p>
        <Input
          value={formData.license}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              license: e.target.value,
            }));
          }}
          placeholder="ใบอนุญาตเลขที่ :"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={formData.note}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              note: e.target.value,
            }));
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EmployeeCreate;
