import { createClient } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getSOWService = async (account_uuid) => {
  const { data } = await supabase
    .from("site_sow")
    .select()
    .eq("account_uuid", account_uuid)
    .order("id");
  return await data;
};

export const getSOWByUuidService = async (site_uuid) => {
  const { data } = await supabase
    .from("site_sow")
    .select()
    .eq("site_uuid", site_uuid);
  return await data;
};

export const setSOWService = async (payload, file) => {
  payload["sow_uuid"] = uuidv4();
  payload["file"] = `docs/${payload.site_uuid}/sow/${payload["sow_uuid"]}.pdf`;

  const { data: insertData, error: insertError } = await supabase
    .from("site_sow")
    .insert(payload)
    .select();

  if (insertError) {
    return insertError;
  }

  const { data: update, error: errorBucket } = await supabase.storage
    .from("siamai")
    .upload(payload["file"], file);
  if (errorBucket) {
    return errorBucket;
  } else {
    return await update;
  }
};

export const updateSOWService = async (payload) => {
  const { data } = await supabase
    .from("site_sow")
    .update(payload)
    .eq("site_uuid", payload.site_uuid);
  return await data;
};

export const deleteSOWService = async (id) => {
  const { data: deleteData } = await supabase
    .from("site_sow")
    .delete()
    .select()
    .eq("id", id);
  const { data: deleteBucket } = await supabase.storage
    .from("siamai")
    .remove([deleteData[0].file]);

  return await deleteBucket;
};
