import {
  Circle,
  Html,
  OrbitControls,
  Stats,
  useProgress,
} from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import React, { Suspense, useState, useEffect, startTransition } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function Loader() {
  const { progress } = useProgress();
  //console.log(progress);
  return <Html center>{progress} % loaded</Html>;
}

const ModelContent = () => {
  const gltf = useLoader(GLTFLoader, "model.gltf");
  return (
    <>
      <directionalLight
        position={[-1.3, 6.0, 4.4]}
        castShadow
        intensity={Math.PI * 1}
      />
      <primitive
        object={gltf?.scene}
        position={[1, 1, 0]}
        children-0-castShadow
      />
      <OrbitControls target={[1, 1, 0]} />
    </>
  );1
};

const Model = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    startTransition(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Canvas camera={{ position: [-1.5, 2, 4] }} shadows>
      <Suspense fallback={<Loader />}>
        {!loading && <ModelContent />}
      </Suspense>
    </Canvas>
  );
};

export default Model;
