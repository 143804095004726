import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getVMSService = async (site_uuid) => {
  const { data } = await supabase
    .from("vms")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id", { ascending: false });
  return await data;
};

export const getVMSTodayService = async (site_uuid) => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 7);
  let isoString = currentDate.toISOString();
  const { data } = await supabase
    .from("vms")
    .select()
    .eq("site_uuid", site_uuid)
    .gte("created_at", isoString.split("T")[0])
    .order("id", { ascending: false });
  return await data;
};

export const getVMSDestinationService = async (site_uuid, destination) => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 7);
  let isoString = currentDate.toISOString();
  const { data } = await supabase
    .from("vms")
    .select()
    .eq("site_uuid", site_uuid)
    .eq("destination", destination)
    .gte("created_at", isoString.split("T")[0])
    .order("id", { ascending: false });
  return await data;
};

export const getVMSNotExitService = async (site_uuid) => {
  const { data } = await supabase
    .from("vms")
    .select()
    .eq("site_uuid", site_uuid)
    .is("exit_datetime", null)
    .order("id", { ascending: false });
  return await data;
};

export const stampExitService = async (vms_uuid) => {
  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 7);
  let isoString = currentDate.toISOString();
  const { data } = await supabase
    .from("vms")
    .update({ exit_datetime: isoString, exit_name: "ยืนยันการออกพื้นที่โดย Admin" })
    .eq("vms_uuid", vms_uuid);
  return await data;
};
