import React, { useState } from "react";
import { Layout, Table, Modal, Button } from "antd";
import { useSelector } from "react-redux";
import SiteCreate from "../components/SiteCreate";
import SiteEdit from "../components/SiteEdit";
import SiteView from "../components/SiteView";
import moment from "moment";

const { Content: AntdContent } = Layout;

const columns = [
  {
    title: "ชื่อหน่วยงาน",
    dataIndex: "site_name",
    key: "site_name",
  },
  {
    title: "ที่อยู่",
    dataIndex: "site_address",
    key: "site_address",
  },
  {
    title: "วันเริ่มสัญญา",
    dataIndex: "contract_start_date",
    key: "contract_start_date",
    render: (_, payload) => {
      return (
        <div>
          {payload.contract_start_date
            ? moment(payload.contract_start_date).format("DD/MM/YYYY")
            : "ไม่มีวันเริ่มสัญญา"}
        </div>
      );
    },
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    width: 300,
    key: "x",
    render: (_, payload) => {
      return (
        <div>
          <SiteView payload={payload} />{" "}
          <Button
            type="primary"
            onClick={() => {
              localStorage.setItem("site", JSON.stringify(payload.site_uuid));
              window.open(`/docs`);
            }}
            style={{
              backgroundColor: "green",
              color: "white",
            }}
          >
            ดูเอกสาร
          </Button>{" "}
          <SiteEdit payload={payload} />
        </div>
      );
    },
  },
];

const ContentComp = ({
  colorBgContainer,
  borderRadiusLG,
  account,
  accountLimit,
}) => {
  const siteAccount = useSelector((state) => state.site.siteAccount);

  const [isModalOpen, setIsModalOpen] = useState(
    !localStorage.getItem("site_youtube")
  );

  const handleModalClose = () => {
    localStorage.setItem("site_youtube", true);
    setIsModalOpen(false);
  };

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายชื่อหน่วยงาน</div>
        <SiteCreate account={account} accountLimit={accountLimit} />
      </div>
      {/* <Modal
        title="คู่มือการใช้งาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/3ilvSmmXVNk?si=eruTzA92hQgZ0AJj"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}
      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.site_note}</p>
          ),
        }}
        dataSource={siteAccount.map((item) => ({
          ...item,
          account,
          accountLimit,
        }))}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;
