import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getProfile = async () => {
  const account_uuid = JSON.parse(localStorage.getItem("account"))[
    "account_uuid"
  ];
  const { data } = await supabase
    .from("account")
    .select()
    .eq("account_uuid", account_uuid);
  return data;
};

export const updateProfile = async (data) => {
  const account_uuid = JSON.parse(localStorage.getItem("account"))[
    "account_uuid"
  ];
  const { error } = await supabase
    .from("account")
    .update(data)
    .eq("account_uuid", account_uuid);
  return error;
};
