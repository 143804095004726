import React from "react";
import { Layout } from "antd";

const { Footer: AntdFooter } = Layout;

const FooterComp = () => {
  return (
    <AntdFooter style={{ textAlign: "center" }}>
      SIAM AI ©{new Date().getFullYear()} Created by SIAM A.I. CO., LTD.
    </AntdFooter>
  );
};

export default FooterComp;
