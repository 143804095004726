import React, { useState } from "react";
import { Button, Modal, Input, DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  uploadFileService,
  deleteFileService,
  setTrainingService,
} from "../services/training";
import moment from "moment";

const SiteCreate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    time: "",
    trainer: "",
    position: "",
    objective: "",
    scope: "",
    target: "",
    fileContent: "",
    fileListMember: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    start_date: null,
  });

  const [loading, setLoading] = useState(false);

  const handleAddCheckpoint = async () => {
    setLoading(true);

    await setTrainingService({
      ...formData,
      site_uuid: JSON.parse(localStorage.getItem("site")),
    });

    setLoading(false);
    window.location.reload();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleAddCheckpoint();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มหัวข้อการอบรม
      </Button>
      <Modal
        title="สร้างหัวข้อการอบรม"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <Button
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "#F5F5F5",
            borderColor: "#A4A2A2",
            color: "#000000",
          }}
        >
          ข้อมูลการอบรม
        </Button>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>หัวข้อการอบรม:</p>
            <Input
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder="หัวข้อการอบรม"
              className="w-[522px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "160px" }}>วันที่อบรม:</p>
            <DatePicker
              className="w-full"
              format="DD/MM/YYYY"
              value={
                formData.start_date
                  ? moment(new Date(formData.start_date))
                  : null
              }
              onChange={(date, dateString) =>
                setFormData((prevState) => ({
                  ...prevState,
                  start_date: date,
                }))
              }
              placeholder="วันที่เริ่มสัญญา"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>เวลาที่อบรม:</p>
            <Input
              value={formData.time}
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
              placeholder="เวลาที่อบรม"
              className="w-[200px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ชื่อผู้อบรม:</p>
            <Input
              value={formData.trainer}
              onChange={(e) =>
                setFormData({ ...formData, trainer: e.target.value })
              }
              placeholder="ชื่อผู้อบรม"
              className="w-[200px]"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ตำแหน่ง:</p>
            <Input
              value={formData.position}
              onChange={(e) =>
                setFormData({ ...formData, position: e.target.value })
              }
              placeholder="ตำแหน่ง"
              className="w-[200px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>วัตถุประสงค์:</p>
            <Input
              value={formData.objective}
              onChange={(e) =>
                setFormData({ ...formData, objective: e.target.value })
              }
              placeholder="วัตถุประสงค์"
              className="w-[522px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ขอบเขต:</p>
            <Input
              value={formData.scope}
              onChange={(e) =>
                setFormData({ ...formData, scope: e.target.value })
              }
              placeholder="ขอบเขตของหลักสูตร"
              className="w-[522px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>กลุ่มเป้าหมาย:</p>
            <Input
              value={formData.target}
              onChange={(e) =>
                setFormData({ ...formData, target: e.target.value })
              }
              placeholder="กลุ่มเป้าหมายผู้เข้าอบรม"
              className="w-[522px]"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SiteCreate;
