import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getCheckpointByIDService = async (patrol_id) => {
  const { data } = await supabase
    .from("patrol")
    .select()
    .eq("id", patrol_id);
  return await data[0];
};

export const getCheckpointService = async (site_uuid) => {
  const { data } = await supabase
    .from("site_patrol_checkpoint")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id");
  return await data;
};

export const getCheckpointReportBySiteService = async (site_uuid) => {
  const { data } = await supabase
    .from("site_patrol_report")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id", { ascending: false });
  return await data;
};

export const setCheckpointService = async (payload) => {
  const { data } = await supabase
    .from("site_patrol_checkpoint")
    .insert(payload);
  return await data;
};

export const updateCheckpointService = async (payload) => {
  const { data } = await supabase
    .from("site_patrol_checkpoint")
    .update(payload)
    .eq("checkpoint_uuid", payload.checkpoint_uuid);
  return await data;
};

export const deleteCheckpointService = async (id) => {
  const { data } = await supabase
    .from("site_patrol_checkpoint")
    .delete()
    .eq("id", id);
  return await data;
};
