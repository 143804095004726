import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getDeviceService = async (product, site_uuid) => {
  const { data } = await supabase
    .from(`site_${product}_device`)
    .select()
    .eq("site_uuid", site_uuid)
    .order("id");
  return await data;
};

export const getDeviceByAccountService = async (product, account_uuid) => {
  const { data: account_data } = await supabase
    .from("account_site")
    .select()
    .eq("account_uuid", account_uuid)
    .order("id");

  var result = [];

  for (let i = 0; i < account_data.length; i++) {
    const { data: site_data } = await supabase
      .from(`site_${product}_device`)
      .select()
      .eq("site_uuid", account_data[i].site_uuid);
    result = [...result, ...site_data];
  }

  return result;
};

export const setDeviceService = async (product, payload) => {
  await supabase.from(`site_${product}_device`).insert(payload);

  if (product == "patrol") {
    let data = await getDeviceService(product, payload.site_uuid);
    data = data.reverse();
    await supabase.from("site_patrol_geolocation").insert({
      device_uuid: data[0].device_uuid,
      site_uuid: data[0].site_uuid,
      lat: 0,
      long: 0,
    });
  }
};

export const updateDeviceService = async (product, payload) => {
  const { data } = await supabase
    .from(`site_${product}_device`)
    .update(payload)
    .eq("device_uuid", payload.device_uuid);
  return await data;
};

export const deleteDeviceService = async (product, id) => {
  if (product == "patrol") {
    const { data } = await supabase
      .from(`site_${product}_device`)
      .select()
      .eq("id", id);
    await supabase
      .from("site_patrol_geolocation")
      .delete()
      .eq("device_uuid", data[0].device_uuid);
  }
  const { data } = await supabase
    .from(`site_${product}_device`)
    .delete()
    .eq("id", id);
  return await data;
};
