import React, { useState, useEffect } from "react";
import { Layout, Table, Modal } from "antd";
import { getDeviceService } from "../services/device";
import { useSelector, useDispatch } from "react-redux";
import { setDevice } from "../reducers/deviceSlice";

import DeviceCreate from "../components/DeviceCreate";
import DeviceEdit from "../components/DeviceEdit";

import Delete from "../components/Delete";
import { deleteDeviceService } from "../services/device";

import QRCodeCanvas from "qrcode.react";

const { Content: AntdContent } = Layout;

const systemName = {
  1: "vms",
  2: "patrol",
};

const columns = [
  {
    title: "ชื่ออุปกรณ์",
    dataIndex: "device_name",
    key: "device_name",
  },
  {
    title: "เวอร์ชั่น",
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: "Access Key",
    dataIndex: "device_uuid",
    key: "device_uuid",
    render: (_, { device_uuid }) => (
      <>
        {JSON.parse(localStorage.getItem("system")) === 1 ? (
          <QRCodeCanvas value={device_uuid} level={"L"} size={248} />
        ) : (
          <>{device_uuid}</>
        )}
      </>
    ),
  },
  {
    title: "การใช้งาน",
    dataIndex: "link",
    key: "link",
    render: (_, { mode, account_uuid, device_uuid, site_uuid }) => (
      <>
        {JSON.parse(localStorage.getItem("system")) === 1 && (
          <a
            onClick={(event) => {
              event.preventDefault();
              navigator.clipboard
                .writeText(device_uuid)
                .then(() => {
                  //console.log("URL copied to clipboard:", device_uuid);
                  alert("ถูกคัดลอกแล้ว: " + device_uuid);
                })
                .catch((error) => {
                  console.error("Error copying to clipboard: ", error);
                  alert("เกิดข้อผิดพลาดในการคัดลอกลิงก์: " + error);
                });
            }}
          >
            คลิกเพื่อคัดลอก
          </a>
        )}
        {JSON.parse(localStorage.getItem("system")) === 2 && (
          <a
            href={`https://app-siamai.web.app/${
              systemName[JSON.parse(localStorage.getItem("system"))]
            }?version=${mode}&device=${device_uuid}`}
            onClick={(event) => {
              event.preventDefault();
              const url = `https://app-siamai.web.app/${
                systemName[JSON.parse(localStorage.getItem("system"))]
              }?version=${mode}&device=${device_uuid}`;
              navigator.clipboard
                .writeText(url)
                .then(() => {
                  //console.log("URL copied to clipboard:", url);
                  // แสดงข้อความหรือประกาศว่าลิงก์ถูกคัดลอกแล้ว
                  alert("ลิงก์ถูกคัดลอกแล้ว: " + url);
                })
                .catch((error) => {
                  console.error("Error copying to clipboard: ", error);
                  // แสดงข้อความหรือประกาศว่ามีข้อผิดพลาดเกิดขึ้นในขณะคัดลอก
                  alert("เกิดข้อผิดพลาดในการคัดลอกลิงก์: " + error);
                });
            }}
          >
            คลิกเพื่อคัดลอกลิงก์
          </a>
        )}
      </>
    ),
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    key: "x",
    render: (_, payload) => {
      return (
        <div className="space-x-2">
          <DeviceEdit
            system={systemName[JSON.parse(localStorage.getItem("system"))]}
            payload={payload}
          />
          <Delete
            system={systemName[JSON.parse(localStorage.getItem("system"))]}
            func={deleteDeviceService}
            id={payload.id}
          />
        </div>
      );
    },
  },
];

const ContentComp = ({
  system,
  colorBgContainer,
  borderRadiusLG,
  site_uuid,
  accountLimit,
}) => {
  const dispatch = useDispatch();
  const device = useSelector((state) => state.device.device);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(
    !localStorage.getItem("device_youtube")
  );

  const handleModalClose = () => {
    localStorage.setItem("device_youtube", true);
    setIsModalOpen(false);
  };

  const handleDeviceChange = async () => {
    setLoading(true);
    const data = await getDeviceService(system, site_uuid);
    dispatch(setDevice(data));
    setLoading(false);
  };

  useEffect(() => {
    handleDeviceChange();
  }, [site_uuid, system]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายชื่ออุปกรณ์</div>
        <DeviceCreate
          system={system}
          site_uuid={site_uuid}
          accountLimit={accountLimit}
        />
      </div>

      {/* <Modal
        title="คู่มือการใช้งาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/T2VNqGa7n2E?si=NPP3IvfNtHr2aezi"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}

      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.device_description}
            </p>
          ),
        }}
        dataSource={device}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;
