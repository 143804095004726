import React, { useState, useEffect } from "react";
import { Layout, Table, Modal } from "antd";
import { getSubContractService } from "../../services/subcontract";
import { useSelector, useDispatch } from "react-redux";
import { setSubContract } from "../../reducers/subcontractSlice";

import SubContractCreate from "../../components/SubContractCreate";
import SubContractEdit from "../../components/SubContractEdit";

import Delete from "../../components/Delete";
import { deleteSubContractService } from "../../services/subcontract";

const { Content: AntdContent } = Layout;

const systemName = {
  1: "vms",
  2: "patrol",
};

const columns = [
  {
    title: "ชื่อบริษัทผู้มาติดต่อ (TH)",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "ชื่อบริษัทผู้มาติดต่อ (ENG)",
    dataIndex: "company_eng_name",
    key: "company_eng_name",
  },
  {
    title: "ที่อยู่",
    dataIndex: "company_address",
    key: "company_address",
    width: "300px",
  },
  {
    title: "กลุ่ม",
    dataIndex: "company_group",
    key: "company_group",
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    key: "x",
    render: (_, payload) => {
      return (
        <div className="space-x-2">
          <SubContractEdit
            system={systemName[JSON.parse(localStorage.getItem("system"))]}
            payload={payload}
          />
          <Delete
            system={systemName[JSON.parse(localStorage.getItem("system"))]}
            func={deleteSubContractService}
            id={payload.id}
          />
        </div>
      );
    },
  },
];

const ContentComp = ({
  system,
  colorBgContainer,
  borderRadiusLG,
  site_uuid,
  accountLimit,
}) => {
  const dispatch = useDispatch();
  const subcontract = useSelector((state) => state.subcontract.subcontract);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(
    !localStorage.getItem("subcontract_youtube")
  );

  const handleModalClose = () => {
    localStorage.setItem("subcontract_youtube", true);
    setIsModalOpen(false);
  };

  const handleSubContractChange = async () => {
    setLoading(true);
    const data = await getSubContractService(site_uuid);
    dispatch(setSubContract(data));
    setLoading(false);
  };

  useEffect(() => {
    handleSubContractChange();
  }, [site_uuid, system]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายชื่อบริษัทผู้มาติดต่อ</div>
        <SubContractCreate
          system={system}
          site_uuid={site_uuid}
          accountLimit={accountLimit}
        />
      </div>

      {/* <Modal
        title="คู่มือการใช้งาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/T2VNqGa7n2E?si=NPP3IvfNtHr2aezi"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}

      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.company_description}
            </p>
          ),
        }}
        dataSource={subcontract}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;

