import React, { useState, useEffect } from "react";
import { Layout, Table, Modal } from "antd";
import { QRCodeCanvas } from "qrcode.react";
import { useSelector, useDispatch } from "react-redux";
import { getCheckpointService } from "../../services/checkpoint";
import { setCheckpoint } from "../../reducers/checkpointSlice";

import CheckpointCreate from "../../components/CheckpointCreate";
import CheckpointEdit from "../../components/CheckpointEdit";
import Delete from "../../components/Delete";

import { deleteCheckpointService } from "../../services/checkpoint";
import CheckpointGen from "../../components/CheckpointGen";

const { Content: AntdContent } = Layout;

const columns = [
  {
    title: "ชื่อจุดตรวจ",
    dataIndex: "checkpoint_name",
    key: "checkpoint_name",
  },
  {
    title: "คิวอาร์โค้ด",
    dataIndex: "",
    key: "x",
    render: (_, payload) => {
      return (
        <div>
          <QRCodeCanvas
            value={payload.checkpoint_uuid}
            level={"L"}
            size={248}
            // imageSettings={{
            //   src: "https://SIAM AI.web.app/short-logo.png",
            //   x: undefined,
            //   y: undefined,
            //   height: 64,
            //   width: 64,
            //   excavate: true,
            // }}
          />
        </div>
      );
    },
  },
  {
    title: "ระยะไม่เกิน (เมตร)",
    dataIndex: "checkpoint_distance_limit",
    key: "checkpoint_distance_limit",
  },
  {
    title: "latitude",
    dataIndex: "checkpoint_lat",
    key: "checkpoint_lat",
  },
  {
    title: "longitude",
    dataIndex: "checkpoint_long",
    key: "checkpoint_long",
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    key: "x",
    render: (_, payload) => {
      return (
        <div>
          <CheckpointEdit payload={payload} />{" "}
          <Delete
            system={null}
            func={deleteCheckpointService}
            id={payload.id}
          />
        </div>
      );
    },
  },
];

const ProgramPage = ({
  accountLimit,
  site_uuid,
  colorBgContainer,
  borderRadiusLG,
}) => {
  const dispatch = useDispatch();
  const checkpoint = useSelector((state) => state.checkpoint.checkpoint);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(
    !localStorage.getItem("create_checkpoint_youtube")
  );

  const handleModalClose = () => {
    localStorage.setItem("create_checkpoint_youtube", true);
    setIsModalOpen(false);
  };

  const handleCheckpointChange = async () => {
    setLoading(true);
    const data = await getCheckpointService(site_uuid);
    dispatch(setCheckpoint(data));
    setLoading(false);
  };

  useEffect(() => {
    handleCheckpointChange();
  }, [site_uuid]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายชื่อจุดตรวจ</div>
        <div className="flex items-center space-x-2">
          <CheckpointCreate site_uuid={site_uuid} accountLimit={accountLimit} />
          <CheckpointGen site_uuid={site_uuid} />
        </div>
      </div>
      {/* <Modal
        title="คู่มือการใช้งาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/3ilvSmmXVNk?si=C-6KqExiWvvUjOmi"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}
      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.checkpoint_note}</p>
          ),
        }}
        dataSource={checkpoint}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ProgramPage;
