import React, { useState } from "react";
import { Tag, Drawer, Space, Image } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";

const App = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const data = [
    {
      key: "1",
      title: "EP.1 คู่มือการเข้าสู่ระบบและลงทะเบียนหน่วยงานใน Centralized Security Platform",
      duration: "0.35 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/16.png",
      link: "https://www.youtube.com/watch?v=Fdzonsj8L3k&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=1",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "2",
      title: "EP.2 การลงข้อมูลพนักงานใน Centralized Security Platform | เพิ่มความปลอดภัยให้กับองค์กรของคุณ",
      duration: "1.01 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/17.png",
      link: "https://www.youtube.com/watch?v=vL_pdVQm844&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=2",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "3",
      title: "EP.3 การจัดรายการอุปกรณ์ใน Centralized Security Platform ง่าย ๆ | เพิ่มประสิทธิภาพการจัดเก็บข้อมูล",
      duration: "0.41 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/18.png",
      link: "https://www.youtube.com/watch?v=w_ERy1abCxg&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=3",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "4",
      title: "EP.4 การจัดการเอกสารสำคัญใน Centralized Security Platform | วิธีง่าย ๆ ที่คุณต้องรู้ !",
      duration: "0.57 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/19.png",
      link: "https://www.youtube.com/watch?v=FW2nVgg4QHE&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=4",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "5",
      title: "EP.5 การจัดเอกสารขอบเขตการทำงานใน Centralized Security Platform | เพิ่มความแม่นยำและมีประสิทธิภาพ",
      duration: "0.29 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/20.png",
      link: "https://www.youtube.com/watch?v=UkNEyvg6_R8&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=5",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "6",
      title: "EP.6 การรายงานประจำวันใน Centralized Security Platform | เพิ่มความรวดเร็วและความแม่นยำของข้อมูล",
      duration: "0.40 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/21.png",
      link: "https://www.youtube.com/watch?v=FJ-p-d3wWQI&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=6",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "7",
      title: "EP.7 การเก็บข้อมูลการอบรมพนักงานใน Centralized Security Platform | เพิ่มประสิทธิภาพการทำงาน",
      duration: "0.51 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/22.png",
      link: "https://www.youtube.com/watch?v=_ZFjTN5BQuc&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=7",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "8",
      title: "EP.8 การตั้งค่า Guard Tour ใน Centralized Security Platform | ระบบตรวจลาดตระเวนอย่างมีระบบและแม่นยำ",
      duration: "1.10 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/23.png",
      link: "https://www.youtube.com/watch?v=AQirgzPj9lg&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=8",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "9",
      title: "ตั้งค่าการแจ้งเตือนผ่าน Line Notify",
      duration: "0.18 นาที",
      preview: "https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/image/13.png",
      link: "https://www.youtube.com/watch?v=kvjPEFbsM_Q&list=PLVYWkn-4EWXE8QzhQ3aMvgQaTo4JzKXH7&index=9",
      disable: localStorage.getItem("accountType") === "1",
    }
  ];

  return (
    <>
      <Space>
        <Tag icon={<YoutubeOutlined />} color="#cd201f" onClick={showDrawer}>
          วิดีโอสอนการใช้งาน
        </Tag>
      </Space>
      <Drawer
        title="วิดีโอสอนการใช้งาน"
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <div className="grid grid-cols-1 items-center">
          {data.map((item, index) => (
            <div key={index}>
              {item.disable && (
                <div className="grid grid-cols-2 items-center space-x-2">
                  <a href={item.link} target="_blank">
                    <Image preview={false} src={item.preview} />
                  </a>
                  <div>
                    <h4>{item.title}</h4>
                    <h6>{item.duration}</h6>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};
export default App;
