import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getSubContractService = async (site_uuid) => {
  const { data } = await supabase
    .from("subcontract")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id");
  return await data;
};

export const setSubContractService = async (payload) => {
  await supabase.from(`subcontract`).insert(payload);
};

export const updateSubContractService = async (payload) => {
  const { data } = await supabase
    .from(`subcontract`)
    .update(payload)
    .eq("company_uuid", payload.company_uuid);
  return await data;
};

export const deleteSubContractService = async (system, id) => {
  const { data } = await supabase.from(`subcontract`).delete().eq("id", id);
  return await data;
};
