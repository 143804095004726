import axios from "axios";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getSiteUUID = async () => {
  const account_uuid = JSON.parse(localStorage.getItem("account")).account_uuid;
  const { data } = await supabase
    .from("account_site")
    .select()
    .eq("account_uuid", account_uuid);

  return data.map((item) => ({
    site_uuid: item?.site_uuid,
    site_name: item?.site_name,
  }));
};

export const getFace = async () => {
  try {
    const buffer = await getSiteUUID();
    const facePromises = buffer.map(async (site) => {
      const { data, error } = await supabase
        .from("patrol_face_embeddings")
        .select()
        .eq("site_uuid", site.site_uuid)
        .order("id");

      if (error) {
        console.error(
          `Error fetching data for site_uuid ${site.site_uuid}:`,
          error
        );
        return [];
      }

      return data.map((item) => ({
        ...item,
        site_name: site?.site_name,
      }));
    });

    const faces = await Promise.all(facePromises);
    //console.log(faces);
    return faces.flat(); // Flatten the array of arrays into a single array
  } catch (error) {
    console.error("Error in getFace function:", error);
    return [];
  }
};

export const getEmployeeFace = async () => {
  try {
    const buffer = await getSiteUUID();
    const employeeFacePromises = buffer.map(async (site) => {
      const { data, error } = await supabase
        .from("site_employee")
        .select()
        .eq("site_uuid", site.site_uuid)
        .order("id");

      if (error) {
        console.error(
          `Error fetching data for site_uuid ${site.site_uuid}:`,
          error
        );
        return [];
      }

      return data.map((item) => ({
        ...item,
        site_name: site?.site_name,
      }));
    });

    const employeeFaces = await Promise.all(employeeFacePromises);
    return employeeFaces.flat(); // Flatten the array of arrays into a single array
  } catch (error) {
    console.error("Error in getEmployeeFace function:", error);
    return [];
  }
};

export const detectFace = async (image) => {
  let formData = new FormData();
  formData.append("file", image);
  return await axios.post("https://infer-face.siamai.tech/predict", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const detectObject = async (image) => {
  let formData = new FormData();
  formData.append("file", image);
  return await axios.post(
    "https://infer-detection.siamai.tech/predict",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
