import axios from "axios";

export const detectLPR = async (image) => {
    let formData = new FormData();
    formData.append("file", image);
    return await axios.post("https://infer-lpr.siamai.tech/predict", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  