import React, { useEffect } from "react";
import { Layout, Select } from "antd";
import CardMonitor from "../../components/dashboard/CardMonitor";
import { TeamOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { postProgram, postDashboard } from "../../services/program";
import CountUp from "react-countup";
import VMSList from "../../components/dashboard/VMSList";
import Download from "../../components/Download";
const { Content: AntdContent } = Layout;
import {
  getVMSService,
  getVMSDestinationService,
  getVMSNotExitService,
  getVMSTodayService,
} from "../../services/vms";
import { i } from "mathjs";

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const [template, setTemplate] = React.useState([]);
  const [data, setData] = React.useState({});
  const [totalVMS, setTotalVMS] = React.useState([]);
  const [todayVMS, setTodayVMS] = React.useState([]);
  const [notExitVMS, setNotExitVMS] = React.useState([]);
  const [dataList, setDataList] = React.useState({});

  const mapToKeyValue = (input) => {
    return input?.map((item) => {
      return {
        [item.destination]: item.objective,
      };
    });
  };

  async function getDistination(templates) {
    for (const key of templates) {
      const { res, error } = await postDashboard({
        filterDestination: key,
        site_uuid: JSON.parse(localStorage.getItem("site")),
        mode: 1,
      });

      if (!error) {
        setData((prev) => ({
          ...prev,
          [key]: res["data"],
        }));
      }
    }
  }

  useEffect(() => {
    async function getProgram() {
      const { res, error } = await postProgram({
        site_uuid: JSON.parse(localStorage.getItem("site")),
      });
      if (error) {
        //console.log("Error:", res);
        return;
      }
      if (res["template"] === undefined) {
        return;
      }
      setTemplate(mapToKeyValue(res["template"]));
      await getDistination(res?.template.map((item) => item.destination));
      for (const items of res["template"]) {
        const buffer = await getVMSDestinationService(
          JSON.parse(localStorage.getItem("site")),
          items.destination
        );

        setDataList((prev) => ({
          ...prev,
          [items.destination]: buffer,
        }));
      }
    }

    async function fetchData() {
      const { res, error } = await postDashboard({
        filterDestination: "",
        site_uuid: JSON.parse(localStorage.getItem("site")),
        mode: 0,
      });

      if (!error) {
        setData((prev) => ({
          ...prev,
          ["ผู้มาติดต่อ"]: res["enter"],
          ["ยังอยู่ในพื้นที่"]: res["internal"],
        }));
      }
    }

    async function executeTasks() {
      await fetchData();
      await getProgram();
    }
    executeTasks();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getVMSNotExitService(
        JSON.parse(localStorage.getItem("site"))
      );
      setNotExitVMS(result);

      const today = await getVMSTodayService(
        JSON.parse(localStorage.getItem("site"))
      );
      setTodayVMS(today);
    };
    setInterval(() => {
      fetchData();
    }, 15 * 1000);
    fetchData();
  }, [localStorage.getItem("site")]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getVMSService(
        JSON.parse(localStorage.getItem("site"))
      );
      setTotalVMS(result);
    };
    setInterval(() => {
      fetchData();
    }, 15 * 1000);
    fetchData();
  }, [localStorage.getItem("site")]);

  const formatter = (value) => <CountUp end={value} separator="," />;
  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="grid grid-cols-3 gap-3">
        <CardMonitor
          title={
            <div className="flex items-center justify-between">
              <div>จำนวนผู้ติดต่อทั้งหมด (คน)</div>
              <div className="w-8 h-8 flex items-center justify-center rounded-[6px] text-black text-[24px]">
                <Download
                  tip={"โหลดรายงานผู้ติดต่อ"}
                  icon={<CloudDownloadOutlined />}
                  status={"vms"}
                />
              </div>
            </div>
          }
          color={"#FFC13F"}
          icon={<TeamOutlined />}
          description={
            <div className="text-[28px]">
              {totalVMS.length == 1000 ? "1000+" : formatter(totalVMS.length)}
            </div>
          }
          actions={
            <VMSList
              title={"จำนวนผู้ติดต่อทั้งหมด (คน)"}
              data={totalVMS}
              destination={dataList}
            />
          }
        />
        <CardMonitor
          title={"จำนวนผู้ติดต่อ (คน)"}
          color={"green"}
          icon={<TeamOutlined />}
          description={
            <div className="text-[28px]">{formatter(data["ผู้มาติดต่อ"])}</div>
          }
          actions={<VMSList title={"ผู้มาติดต่อ"} data={todayVMS} destination={dataList} />}
        />
        <CardMonitor
          title={"จำนวนผู้ติดต่อที่ยังไม่ออกพื้นที่ (คน)"}
          color={"#FF0000"}
          icon={<TeamOutlined />}
          description={
            <div className="text-[28px]">
              {formatter(data["ยังอยู่ในพื้นที่"])}
            </div>
          }
          actions={
            <VMSList
              title={"จำนวนผู้ติดต่อที่ยังไม่ออกพื้นที่ (คน)"}
              data={notExitVMS}
              destination={dataList}
            />
          }
        />
      </div>
      <div className="grid grid-cols-3 gap-3 mt-5">
        {template?.map((item, index) => (
          <CardMonitor
            key={index}
            title={Object.keys(item)[0]}
            color={"#000000"}
            icon={<TeamOutlined />}
            description={
              <div className="text-[28px]">
                {formatter(data[Object.keys(item)[0]])}
              </div>
            }
            actions={
              <VMSList
                title={Object.keys(item)[0]}
                data={dataList[Object.keys(item)[0]]}
              />
            }
          />
        ))}
      </div>
      {/* <div className="mt-4 grid grid-cols-1 gap-1">
        <CardMonitor
          title={
            <div className="flex justify-between">
              <div>สถิติการเข้ามาติดต่อ</div>
              <Select
                className="ml-4"
                showSearch
                style={{ width: 200 }}
                placeholder="ปีปัจจุบัน"
                options={[
                  { value: "1", label: "เดือนปัจจุบัน" },
                  { value: "2", label: "เดือนก่อน" },
                  { value: "3", label: "2 เดือน" },
                  { value: "4", label: "3 เดือน" },
                  { value: "5", label: "6 เดือน" },
                  { value: "6", label: "1 ปี" },
                  { value: "6", label: "ปีปัจจุบัน" },
                  { value: "6", label: "ปีก่อน" },
                ]}
              />
            </div>
          }
          actions={
            <div className="w-[80vw]">
              <DemoColumn />
            </div>
          }
        />
      </div> */}
    </AntdContent>
  );
};

export default ContentComp;
