import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subcontract: [],
};

const subcontractSlice = createSlice({
  name: "subcontract",
  initialState,
  reducers: {
    setSubContract: (state, action) => {
      state.subcontract = action.payload;
    },
  },
});

export const { setSubContract } = subcontractSlice.actions;
export default subcontractSlice.reducer;
