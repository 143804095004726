import React, { useState, useEffect } from "react";
import { Layout, Table } from "antd";
import ContractCreate from "../../components/ContractCreate";
import Delete from "../../components/Delete";
import {
  getContractByUuidService,
  deleteContractService,
} from "../../services/contract";

const { Content: AntdContent } = Layout;

const columns = [
  {
    title: "ชื่อเอกสาร",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ไฟล์ PDF",
    dataIndex: "file",
    key: "file",
    render: (_, payload) => {
      return (
        <embed
          src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${payload.file}`}
          type="application/pdf"
          width="800"
          height="600"
        />
      );
    },
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    width: 300,
    key: "x",
    render: (_, payload) => {
      return (
        <div className="space-x-2">
          <Delete system={null} func={deleteContractService} id={payload.id} />
        </div>
      );
    },
  },
];

const ContentComp = ({ colorBgContainer, borderRadiusLG, site_uuid }) => {
  const [loading, setLoading] = useState(false);
  const [form, setFrom] = useState([]);

  const handleEmployeeChange = async () => {
    setLoading(true);
    const data = await getContractByUuidService(site_uuid);
    setFrom(data);
    setLoading(false);
  };

  useEffect(() => {
    handleEmployeeChange();
  }, [site_uuid]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">เอกสารสัญญา</div>
        <ContractCreate />
      </div>
      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.note}</p>
          ),
        }}
        dataSource={form}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;
