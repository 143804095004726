import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Select } from "antd";
import {
  updateSubContractService,
  getSubContractService,
} from "../services/subcontract";
import { useDispatch } from "react-redux";
import { setSubContract } from "../reducers/subcontractSlice";

const SubContractEdit = ({ system, payload }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_uuid, setSiteUUID] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyENGName, setCompanyENGName] = useState("");
  const [companyUUID, setCompanyUUID] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyGroup, setCompanyGroup] = useState("กลุ่ม 1");
  const [companyDescription, setCompanyDescription] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSiteUUID(payload.site_uuid);
    setCompanyUUID(payload.company_uuid);
    setCompanyName(payload.company_name);
    setCompanyENGName(payload.company_eng_name);
    setCompanyGroup(payload.company_group);
    setCompanyAddress(payload.company_address);
    setCompanyDescription(payload.company_description);
  }, [payload]);

  const handleSubContractChange = async () => {
    setLoading(true);
    const data = await getSubContractService(site_uuid);
    dispatch(setSubContract(data));
    setLoading(false);
  };

  const handleUpdateSubContract = async () => {
    setLoading(true);

    await updateSubContractService({
      site_uuid: site_uuid,
      company_uuid: companyUUID,
      company_name: companyName,
      company_eng_name: companyENGName,
      company_group: companyGroup,
      company_address: companyAddress,
      company_description: companyDescription,
    });
    await handleSubContractChange();
    setCompanyName("");
    setCompanyENGName("");
    setCompanyAddress("");
    setCompanyGroup("1");
    setCompanyDescription("");
    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleUpdateSubContract();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        แก้ไข
      </Button>
      <Modal
        title="แก้ไขบริษัทผู้มาติดต่อ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อบริษัทผู้มาติดต่อ (TH):</p>
        <Input
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          placeholder="ชื่อบริษัท (TH)"
        />
        <p>ชื่อบริษัทผู้มาติดต่อ (ENG):</p>
        <Input
          value={companyENGName}
          onChange={(e) => {
            setCompanyENGName(e.target.value);
          }}
          placeholder="ชื่อบริษัท (ENG)"
        />
        <p>ที่อยู่:</p>
        <Input
          value={companyAddress}
          onChange={(e) => {
            setCompanyAddress(e.target.value);
          }}
          placeholder="ที่อยู่"
        />
        <p>เลือกกลุ่ม:</p>
        <Select
          value={companyGroup}
          onChange={(value) => {
            setCompanyGroup(value);
          }}
          style={{ width: "100%" }}
          placeholder="เลือกกลุ่ม"
          options={[
            { label: "กลุ่ม 1", value: "กลุ่ม 1" },
            { label: "กลุ่ม 2", value: "กลุ่ม 2" },
            { label: "กลุ่ม 3", value: "กลุ่ม 3" },
            { label: "กลุ่ม 4", value: "กลุ่ม 4" },
            { label: "กลุ่ม 5", value: "กลุ่ม 5" },
          ]}
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={companyDescription}
          onChange={(e) => {
            setCompanyDescription(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default SubContractEdit;
