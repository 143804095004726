import React, { useState, useEffect } from "react";
import { Layout, Upload, Input, Button } from "antd";
import { uploadImageService, deleteImageService } from "../services/upload";
import { getProfile, updateProfile } from "../services/profile";
import {
  getNotiAccountService,
  setNotiAccountService,
  updateNotiAccountService,
} from "../services/noti";
const { Content: AntdContent } = Layout;

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const [image, setImage] = React.useState([]);
  const [formData, setFormData] = React.useState({ company_logo: "" });
  const [noti, setNoti] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [account_uuid, setAccount_uuid] = useState(
    JSON.parse(localStorage.getItem("account")).account_uuid
  );

  const handleNotiChange = async () => {
    setLoading(true);
    const data = await getNotiAccountService(account_uuid);
    setNoti(data);
    setLoading(false);
  };

  const handleAddNoti = async () => {
    setLoading(true);
    if (noti?.created_at) {
      await updateNotiAccountService(noti);
    } else {
      await setNotiAccountService(noti);
    }

    await handleNotiChange();
    setLoading(false);
  };

  useEffect(() => {
    handleNotiChange();
  }, [account_uuid]);

  const dummyImageRequest = async ({ file, onSuccess }) => {
    const id = JSON.parse(localStorage.getItem("account"))["id"];
    file.newName = `company_logo.${file.name.split(".")[1]}`;
    const { data, error } = await uploadImageService(id, file);
    if (error) {
      //console.log("error", error);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        company_logo: `profile/${id}/${file.newName}`,
      }));
      setImage([
        {
          uid: file.uid,
          name: file.newName,
          status: "done",
          url: URL.createObjectURL(file),
        },
      ]);
      updateProfile({
        company_logo: `profile/${id}/${file.newName}`,
      });
      onSuccess("ok");
    }
    setImage([
      {
        uid: file.uid,
        name: file.newName,
        status: "done",
        url: URL.createObjectURL(file),
      },
    ]);
    onSuccess("ok");
  };

  const onImageRemove = async (file) => {
    const id = JSON.parse(localStorage.getItem("account"))["id"];
    file.newName = `company_logo.${file.name.split(".")[1]}`;
    const { data, error } = await deleteImageService(id, file);
    if (error) {
      //console.log("error", error);
    } else {
      setImage([]);
      setFormData((prevState) => ({
        ...prevState,
        company_logo: "",
      }));
      updateProfile({
        company_logo: "",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProfile();
      setFormData(data[0]);
    };
    fetchData();
  }, []);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="text-2xl mb-4">ข้อมูลส่วนตัว</div>
      <Upload
        customRequest={dummyImageRequest}
        onRemove={onImageRemove}
        listType="picture-card"
        fileList={
          image && image.length === 0 && formData.company_logo
            ? [
                {
                  uid: "-1",
                  name: "company_logo.png",
                  status: "done",
                  url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.company_logo}`,
                },
              ]
            : image
        }
        maxCount={1}
        accept="image/jpeg,image/png"
      >
        {image.length === 0 && !formData.company_logo && "logo บริษัท"}
      </Upload>
      <div className="grid grid-cols-1 gap-1 justify-center">
        <p className="subpixel-antialiased text-base font-bold">Line Token</p>
        <Input
          value={noti?.line_token}
          style={{
            width: "300px",
          }}
          placeholder="line"
          onChange={(e) => {
            setNoti({
              ...noti,
              line_token: e.target.value,
              account_uuid: account_uuid,
            });
          }}
        />
      </div>
      {noti?.created_at ? (
        <Button
          className="mt-4"
          style={{
            width: "300px",
          }}
          type="primary"
          onClick={handleAddNoti}
          disabled={loading}
        >
          อัพเดตข้อมูล
        </Button>
      ) : (
        <Button
          className="mt-4"
          style={{
            width: "300px",
          }}
          type="primary"
          onClick={handleAddNoti}
          disabled={loading}
        >
          บันทึกข้อมูล
        </Button>
      )}
    </AntdContent>
  );
};

export default ContentComp;
