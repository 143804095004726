import React, { useState, useEffect, useRef } from "react";
import { Modal, Table, Tag, Image, Input, AutoComplete } from "antd";
import moment from "moment";
import { getPatrolSearchService } from "../../services/dashboard";

const { Search } = Input;

const keyword = {
  0: "บุคคล",
  1: "จักรยาน",
  2: "รถยนต์",
  3: "รถจักรยานยนต์",
  4: "ไม่สามารถระบุได้",
  5: "ไม่สามารถระบุได้",
  6: "ไม่สามารถระบุได้",
  7: "รถบรรทุก",
  8: "ไม่สามารถระบุได้",
  9: "สัญญาณไฟจราจร",
  10: "หัวจ่ายน้ำดับเพลิง",
  11: "ป้ายหยุด",
  12: "ไม่สามารถระบุได้",
  13: "ไม่สามารถระบุได้",
  14: "นก",
  15: "แมว",
  16: "สุนัข",
  17: "ไม่สามารถระบุได้",
  18: "ไม่สามารถระบุได้",
  19: "วัว",
  20: "ไม่สามารถระบุได้",
  21: "ไม่สามารถระบุได้",
  22: "ไม่สามารถระบุได้",
  23: "ไม่สามารถระบุได้",
  24: "กระเป๋าเป้สะพายหลัง",
  25: "ร่ม",
  26: "กระเป๋าถือ",
  27: "เน็คไท",
  28: "กระเป๋าเดินทาง",
  29: "ไม่สามารถระบุได้",
  30: "ไม่สามารถระบุได้",
  31: "ไม่สามารถระบุได้",
  32: "ไม่สามารถระบุได้",
  33: "ไม่สามารถระบุได้",
  34: "ไม่สามารถระบุได้",
  35: "ไม่สามารถระบุได้",
  36: "ไม่สามารถระบุได้",
  37: "ไม่สามารถระบุได้",
  38: "ไม่สามารถระบุได้",
  39: "ขวด",
  40: "แก้วไวน์",
  41: "ถ้วย",
  42: "ส้อม",
  43: "มีด",
  44: "ช้อน",
  45: "ชาม",
  46: "ไม่สามารถระบุได้",
  47: "ไม่สามารถระบุได้",
  48: "ไม่สามารถระบุได้",
  49: "ไม่สามารถระบุได้",
  50: "ไม่สามารถระบุได้",
  51: "ไม่สามารถระบุได้",
  52: "ไม่สามารถระบุได้",
  53: "ไม่สามารถระบุได้",
  54: "ไม่สามารถระบุได้",
  55: "ไม่สามารถระบุได้",
  56: "เก้าอี้",
  57: "โซฟา",
  58: "ไม้กระถาง",
  59: "เตียง",
  60: "โต๊ะรับประทานอาหาร",
  61: "ห้องน้ำ",
  62: "ทีวี",
  63: "แล็ปท็อป",
  64: "เมาส์",
  65: "ไม่สามารถระบุได้",
  66: "แป้นพิมพ์",
  67: "โทรศัพท์มือถือ",
  68: "ไมโครเวฟ",
  69: "เตาอบ",
  70: "เครื่องปิ้งขนมปัง",
  71: "อ่างล้างจาน",
  72: "ตู้เย็น",
  73: "หนังสือ",
  74: "นาฬิกา",
  75: "แจกัน",
  76: "กรรไกร",
  77: "ตุ๊กตาหมี",
  78: "เครื่องเป่าผม",
  79: "แปรงสีฟัน",
};

const searchResult = (query) => {
  const results = Object.entries(keyword).filter(
    ([key, value]) => value.includes(query) && value !== "ไม่สามารถระบุได้"
  );
  return results.map(([key, value]) => ({
    value: value,
    label: value,
  }));
};

const SearchComponent = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const columns = [
    {
      title: "วันที่",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => (
        <div>
          {moment(created_at.replace("+00:00", "")).format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        </div>
      ),
    },
    {
      title: "ประจำจุด",
      dataIndex: "checkpoint_name",
      key: "checkpoint_name",
    },
    {
      title: "รายงาน",
      dataIndex: "name",
      key: "name",
      render: (
        _,
        {
          report_name,
          report_message,
          report_cause,
          report_where,
          report_time,
          report_who,
        }
      ) => (
        <div>
          {report_name ? (
            <div>
              <p className="font-bold mb-0">รายงานสถานการณ์:</p> {report_name}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">สาเหตุ:</p> {report_cause}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">สถานที่เกิดเหตุ:</p> {report_where}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">เวลาที่เกิดเหตุ:</p> {report_time}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">ผู้รับผิดชอบหรือรับทราบ:</p>{" "}
              {report_who}
            </div>
          ) : (
            <div>
              <p className="font-bold mb-0">บันทึกข้อความ:</p> {report_message}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "ประเภทรายงาน",
      key: "report_status",
      dataIndex: "report_status",
      render: (_, { report_status }) => (
        <>
          {
            <Tag
              color={report_status === "normal" ? "green" : "red"}
              key={report_status}
            >
              {report_status.toUpperCase()}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "ภาพถ่าย",
      dataIndex: "report_img",
      key: "report_img",
      render: (_, { report_img }) => {
        return (
          <div>
            {report_img.map((img, index) => (
              <Image
                width={100}
                src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/${img}`}
                key={index}
              />
            ))}
          </div>
        );
      },
    },
    {
      title: "VisionLLM",
      key: "llm",
      dataIndex: "llm",
      render: (_, { llm }) => (
        <div className="grid grid-cols-1 gap-2">
          {llm?.map((text, index) => (
            <div>{text}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Detection",
      key: "cls",
      dataIndex: "cls",
      render: (_, { cls }) => {
        // สร้าง object ที่เก็บจำนวนครั้งของแต่ละ keyword
        const keywordCounts = {};
        cls?.forEach((keyword) => {
          keywordCounts[keyword] = (keywordCounts[keyword] || 0) + 1;
        });
        // ดึงคีย์เวิร์ดที่พบมาใช้
        const foundKeywords = Object.keys(keywordCounts);
        // สร้างสีจากจำนวนคีย์เวิร์ดที่พบ

        return (
          <div className="grid grid-cols-1 gap-2">
            {foundKeywords.map((keyword, index) => {
              const count = keywordCounts[keyword] || 0;
              // นำเลข index มาใช้ในการเลือกสี
              const color =
                keyword === "บุคคล"
                  ? "red"
                  : keyword === "รถยนต์" || keyword === "รถจักรยานยนต์"
                  ? "blue"
                  : "";

              return (
                <Tag key={index} style={{ backgroundColor: color }}>
                  <div className="flex items-center justify-center text-center ">
                    {`${count}x${keyword}`}
                  </div>
                </Tag>
              );
            })}
          </div>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };
  const onSelect = async (value) => {
    let data = await getPatrolSearchService(
      value,
      JSON.parse(localStorage.getItem("site"))
    );
    setData(data);
    setOpen(true);
  };

  const handleSearchChange = async (value) => {
    let data = await getPatrolSearchService(
      value,
      JSON.parse(localStorage.getItem("site"))
    );
    setData(data);
    setOpen(true);
  };

  return (
    <div className="flex items-center">
      <AutoComplete
        popupMatchSelectWidth={152}
        style={{ width: 180 }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
      >
        <Search
          placeholder="ค้นหา"
          onSearch={(value) => handleSearchChange(value)}
        />
      </AutoComplete>
      <Modal
        title="ผลการค้นหา"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Table rowKey="id" columns={columns} dataSource={data} />
      </Modal>
    </div>
  );
};

export default SearchComponent;
