import React, { useState, useEffect } from "react";
import { Layout, Table, Tooltip, Button } from "antd";
import StockCreate from "../../components/StockCreate";
import StockEdit from "../../components/StockEdit";
import Delete from "../../components/Delete";
import CardMonitor from "../../components/dashboard/CardMonitor";
import {
  getStockByUuidService,
  deleteStockService,
} from "../../services/stock";
import moment from "moment";
import Download from "../../components/Download";
import { CloudDownloadOutlined, SkinOutlined, FormOutlined, LaptopOutlined, PlusSquareOutlined } from "@ant-design/icons";
import CountUp from "react-countup";

const { Content: AntdContent } = Layout;

const columns = [
  {
    title: "วันที่",
    dataIndex: "created_at",
    key: "created_at",
    render: (_, { created_at }) => (
      <div>
        {moment(created_at.replace("+00:00", "")).format("DD/MM/YYYY HH:mm:ss")}
      </div>
    ),
  },
  {
    title: "ประเภท",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "ชื่อ",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "หน่วย",
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: "วันที่เริ่มใช้งาน",
    dataIndex: "used_date",
    key: "used_date",
    render: (_, payload) => {
      if (!payload.used_date) {
        return <div>ไม่มีข้อมูล</div>;
      }

      const startDate = moment(payload.used_date);
      const now = moment();
      const years = now.diff(startDate, "years");
      startDate.add(years, "years");

      const months = now.diff(startDate, "months");
      startDate.add(months, "months");

      const days = now.diff(startDate, "days");

      let durationText = "";
      if (years > 0) {
        durationText += `${years} ปี `;
      }
      if (months > 0 || years > 0) {
        durationText += `${months} เดือน `;
      }
      durationText += `${days} วัน`;

      return (
        <div>
          {`${moment(payload.used_date).format(
            "DD/MM/YYYY"
          )} (ใช้งาน ${durationText})`}
        </div>
      );
    },
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    width: 300,
    key: "x",
    render: (_, payload) => {
      return (
        <div className="space-x-2">
          <StockEdit payload={payload} />
          <Delete system={null} func={deleteStockService} id={payload.id} />
        </div>
      );
    },
  },
];

const ContentComp = ({ colorBgContainer, borderRadiusLG, site_uuid }) => {
  const [loading, setLoading] = useState(false);
  const [form, setFrom] = useState([]);
  const formatter = (value) => <CountUp end={value} separator="," />;

  const handleEmployeeChange = async () => {
    setLoading(true);
    const data = await getStockByUuidService(site_uuid);
    setFrom(data);
    setLoading(false);
  };

  useEffect(() => {
    handleEmployeeChange();
  }, [site_uuid]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายการอุปกรณ์</div>
        <div className="flex space-x-2">
          <Button type="primary">
            <Download
              tip={"โหลดรายการอุปกรณ์"}
              icon={<CloudDownloadOutlined />}
              status={"stock"}
            ></Download>
          </Button>
          <StockCreate />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-3 mt-4">
        <CardMonitor
          title={<div>จำนวนเครื่องแบบ</div>}
          color={"#FFC13F"}
          icon={
            <Tooltip title={"ข้อมูลนี้คือจำนวนทั้งหมดตั้งแต่เริ่มต้นหน่วยงาน"}>
              <SkinOutlined />
            </Tooltip>
          }
          description={
            <div className="text-[28px]">
              {form.length ? formatter(form.map((item) => item.type === "เครื่องแบบ" ? parseInt(item.amount) : 0).reduce((prev, next) => prev + next)) : "0"}
            </div>
          }
        />
        <CardMonitor
          title={<div>จำนวนอุปกรณ์</div>}
          color={"#FF5733"}
          icon={
            <Tooltip title={"ข้อมูลนี้คือจำนวนทั้งหมดตั้งแต่เริ่มต้นหน่วยงาน"}>
              <LaptopOutlined />
            </Tooltip>
          }
          description={
            <div className="text-[28px]">
              {form.length ? formatter(form.map((item) => item.type === "อุปกรณ์" ? parseInt(item.amount) : 0).reduce((prev, next) => prev + next)) : "0"}
            </div>
          }
        />
        <CardMonitor
          title={<div>จำนวนอุปกรณ์เบิกเพิ่ม</div>}
          color={"#333333"}
          icon={
            <Tooltip title={"ข้อมูลนี้คือจำนวนทั้งหมดตั้งแต่เริ่มต้นหน่วยงาน"}>
              <PlusSquareOutlined />
            </Tooltip>
          }
          description={
            <div className="text-[28px]">
              {form.length ? formatter(form.map((item) => item.type === "อุปกรณ์เบิกเพิ่ม" ? parseInt(item.amount) : 0).reduce((prev, next) => prev + next)) : "0"}
            </div>
          }
        />
        <CardMonitor
          title={<div>จำนวนอุปกรณ์ (TOR)</div>}
          color={"#3357FF"}
          icon={
            <Tooltip title={"ข้อมูลนี้คือจำนวนทั้งหมดตั้งแต่เริ่มต้นหน่วยงาน"}>
              <FormOutlined />
            </Tooltip>
          }
          description={
            <div className="text-[28px]">
              {form.length ? formatter(form.map((item) => item.type === "อุปกรณ์ (TOR)" ? parseInt(item.amount) : 0).reduce((prev, next) => prev + next)) : "0"}
            </div>
          }
        />
      </div>
      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.note}</p>
          ),
        }}
        dataSource={form}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;
