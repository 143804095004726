import React, { useState, useEffect, useRef } from "react";
import { Button, message, Upload, Modal } from "antd";
import { UserOutlined, CarOutlined } from "@ant-design/icons";
import { detectFace, getEmployeeFace, getFace } from "../../services/face";
import { getCheckpointByIDService } from "../../services/checkpoint";
import { detectLPR } from "../../services/lpr";
import { norm } from "mathjs";
import moment from "moment";

function computeSim(feat1, feat2) {
  feat1 = feat1.flat(); // เปลี่ยน feat1 เป็น array 1 มิติ
  feat2 = feat2.flat(); // เปลี่ยน feat2 เป็น array 1 มิติ

  const dotProduct = feat1.reduce(
    (acc, val, index) => acc + val * feat2[index],
    0
  ); // คำนวณ dot product ด้วย reduce
  const normFeat1 = norm(feat1); // คำนวณ norm ของ feat1
  const normFeat2 = norm(feat2); // คำนวณ norm ของ feat2

  const sim = dotProduct / (normFeat1 * normFeat2); // คำนวณค่าความคล้ายคลึง

  return sim;
}

const UploadComponent = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [result, setResult] = useState([]);
  const [employeeFace, setEmployeeFace] = useState([]);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [where, setWhere] = useState([]);
  const canvasRef = useRef(null);

  const lprRequest = ({ file, onSuccess }) => {
    detectLPR(file)
      .then((res) => {
        setFile(file);
        setData2(res.data.data);
        setOpen(true);
        setResult([]);
        setEmployeeFace([]);
        message.success(`ประมวลผลภาพเสร็จสิ้น: ${file.name}`);
        onSuccess("ok");
      })
      .catch((error) => {
        console.error("Error detecting face:", error);
        message.error(`ไม่สามารถประมวลผลภาพ: ${file.name}`);
        onSuccess("error");
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    detectFace(file)
      .then((res) => {
        setFile(file);
        setData(res.data.data);
        setOpen(true);
        setResult([]);
        setEmployeeFace([]);
        message.success(`ประมวลผลใบหน้าเสร็จสิ้น: ${file.name}`);
        onSuccess("ok");
      })
      .catch((error) => {
        console.error("Error detecting face:", error);
        message.error(`ไม่สามารถประมวลผลใบหน้า: ${file.name}`);
        onSuccess("error");
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (data2.length > 0 && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);

          data2.forEach(async (item) => {
            if (item.class_name === "เลขทะเบียน") {
              var [x, y, w, h] = item.box;

              ctx.strokeStyle = "red";
              ctx.lineWidth = 2;
              ctx.strokeRect(x - (x - w), y - (y - h), x - w, y - h);

              ctx.font = "12px Arial";
              ctx.fillStyle = "red";
              ctx.fillText(`ผลลัพธ์: ${item.ocr}`, x - 15, y - 5);
            }
          });
        };
      }
      if (data.length > 0 && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);

          data.forEach((item) => {
            const [x1, y1, x2, y2] = item.bbox;
            const width = x2 - x1;
            const height = y2 - y1;

            ctx.strokeStyle = "red";
            ctx.lineWidth = 2;
            ctx.strokeRect(x1, y1, width, height);
            ctx.font = "16px Arial";
            ctx.fillStyle = "red";
            // ctx.fillText(`อายุ: ${item.age}`, x1, y1 - 5);
          });

          data1.forEach(async (item) => {
            var [x, y, w, h] = item.box;

            const left = x - w / 2;
            const top = y - h / 2;
            const width = w;
            const height = h;

            const newLeft = left * (image.width / 640);
            const newTop = top * (image.height / 640);
            const newWidth = width * (image.width / 640);
            const newHeight = height * (image.height / 640);

            const x1 = newLeft;
            const y1 = newTop;

            const rectWidth = newWidth;
            const rectHeight = newHeight;

            ctx.strokeStyle = "green";
            ctx.lineWidth = 2;
            ctx.strokeRect(x1, y1, rectWidth, rectHeight);

            ctx.font = "16px Arial";
            ctx.fillStyle = "green";
            ctx.fillText(`${item.class_name}`, x1, y1 - 5);
          });
        };
      }
    }, 500);
  }, [canvasRef, data, data1, data2, file]);

  useEffect(() => {
    getFace().then((res) => {
      res.map((feat1) => {
        data.forEach((feat2) => {
          if (
            computeSim(JSON.parse(feat1["embedding"]), feat2["embedding"]) > 0.7
          ) {
            feat1["sim"] = computeSim(
              JSON.parse(feat1["embedding"]),
              feat2["embedding"]
            );

            setResult([...result, feat1]);
            getCheckpointByIDService(feat1["patrol_id"]).then((res) => {
              setWhere([...where, res]);
            });
          }
        });
      });
    });

    getEmployeeFace().then((res) => {
      res.map((feat1) => {
        data.forEach((feat2) => {
          if (feat1) {
            if (computeSim(feat1["vector1"], feat2["embedding"]) > 0.7) {
              feat1["sim"] = computeSim(feat1["vector1"], feat2["embedding"]);
              setEmployeeFace([...employeeFace, feat1]);
            }
          }
        });
      });
    });
  }, [data]);

  const handleclose = () => {
    setOpen(false);
    setResult([]);
    setData([]);
    setData1([]);
    setData2([]);
    setFile(null);
    setWhere([]);
  };

  return (
    <div className="mr-2">
      <Modal
        title={data2.length > 0 ? "ระบบค้นหาป้ายทะเบียน" : "ระบบค้นหาใบหน้า"}
        open={open}
        onOk={handleclose}
        onCancel={handleclose}
      >
        <div className="grid grid-cols-1 gap-2">
          <canvas className="w-[100%]" ref={canvasRef} />
          ผลลัพธ์:{" "}
          {data2.length > 0
            ? data2.length
            : result.length || employeeFace.length}{" "}
          รายการ
          <div className="grid grid-cols-3 gap-2">
            {result.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-1 gap-1 items-center justify-center text-center"
              >
                <img
                  width={150}
                  src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/${item.path}`}
                ></img>
                <div>
                  <p>ความเหมือน: {parseInt(item.sim * 100)}%</p>
                  <p>
                    {moment(item.created_at.replace("+00:00", "")).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </p>
                  <p>หน่วยงาน {item.site_name}</p>
                  <p>
                    สถานที่{" "}
                    {where[index]?.checkpoint_name ||
                      where[index]?.report_where}
                  </p>
                </div>
              </div>
            ))}
            {employeeFace.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-1 gap-1 items-center justify-center text-center"
              >
                <img
                  width={150}
                  src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${item.image1}`}
                ></img>
                <div>
                  <p>ความเหมือน: {parseInt(item.sim * 100)}%</p>
                  <p>
                    {moment(item.created_at.replace("+00:00", "")).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </p>
                  <p>หน่วยงาน {item.site_name}</p>
                  <p>
                    {item?.name} <br />
                    {item?.idcard} <br />
                    {item?.license || "ไม่มีใบอนุญาต"} <br />
                    {item?.phone}
                  </p>
                </div>
              </div>
            ))}
            {data2.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-1 gap-1 items-center justify-center text-center"
              >
                <div>
                  <p>หมายเลข: {item.ocr}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Upload showUploadList={false} customRequest={dummyRequest}>
        <Button icon={<UserOutlined />}>ค้นหาใบหน้า</Button>
      </Upload>
      <Upload
        className="ml-1"
        showUploadList={false}
        customRequest={lprRequest}
      >
        <Button icon={<CarOutlined />}>อ่านป้าย</Button>
      </Upload>
    </div>
  );
};

export default UploadComponent;
