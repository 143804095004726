import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const uploadImageService = async (id, file) => {
  const { data, error } = await supabase.storage
    .from("siamai")
    .upload(`profile/${id}/${file.newName}`, file);
  if (error) {
    await deleteImageService(id, file.newName);
    const { data, error } = await supabase.storage
      .from("siamai")
      .upload(`profile/${id}/${file.newName}`, file);
    if (error) {
      return error;
    }
    return  `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/profile/${id}/${file.newName}`;
  } else {
    return  `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/profile/${id}/${file.newName}`;
  }
};

export const deleteImageService = async (id, file) => {
  const { data, error } = await supabase.storage
    .from("siamai")
    .remove([`profile/${id}/${file.newName}`]);
  if (error) {
    return error;
  } else {
    return data;
  }
};
