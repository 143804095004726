import React, { useState } from "react";
import { Button, Modal, Input, InputNumber, DatePicker, Select } from "antd";
import { setStockService } from "../services/stock";
import moment from "moment";

const EmployeeCreate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    used_date: null,
    amount: "",
    unit: "",
    note: "",
  });

  const [loading, setLoading] = useState(false);

  const handleAddCheckpoint = async () => {
    setLoading(true);

    await setStockService({
      ...formData,
      site_uuid: JSON.parse(localStorage.getItem("site")),
    });

    setLoading(false);
    window.location.reload();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddCheckpoint();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มพัสดุ
      </Button>
      <Modal
        title="เพิ่มอุปกรณ์"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ประเภท:</p>
        <Select
          className="w-full"
          value={formData.type}
          onChange={(value) => {
            setFormData((prevState) => ({
              ...prevState,
              type: value,
            }));
          }}
          placeholder="ประเภท"
          options={[
            { label: "เครื่องแบบ", value: "เครื่องแบบ" },
            { label: "อุปกรณ์", value: "อุปกรณ์" },
            { label: "อุปกรณ์เบิกเพิ่ม", value: "อุปกรณ์เบิกเพิ่ม" },
            { label: "อุปกรณ์ (TOR)", value: "อุปกรณ์ (TOR)" },
          ]}
        />
        <p>ชื่อ:</p>
        <div className="flex items-center space-x-2">
          <Input
            value={formData.name}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
            placeholder="ชื่อ"
          />
        </div>

        <p>จำนวน:</p>
        <div className="flex items-center space-x-2">
          <InputNumber
            value={formData.amount}
            onChange={(value) => {
              setFormData((prevState) => ({
                ...prevState,
                amount: value,
              }));
            }}
            placeholder="จำนวน"
          />
        </div>

        <p>หน่วย:</p>
        <div className="flex items-center space-x-2">
          <Select
            className="w-full"
            value={formData.unit}
            onChange={(value) => {
              setFormData((prevState) => ({
                ...prevState,
                unit: value,
              }));
            }}
            placeholder="หน่วย"
            options={[
              { label: "ชุด", value: "ชุด" },
              { label: "อัน", value: "อัน" },
              { label: "ชิ้น", value: "ชิ้น" },
              { label: "คู่", value: "คู่" },
              { label: "เครื่อง", value: "เครื่อง" },
              { label: "ก้อน", value: "ก้อน" },
              { label: "คัน", value: "คัน" },
              { label: "ใบ", value: "ใบ" },
              { label: "ลูก", value: "ลูก" },
              { label: "ตัว", value: "ตัว" },
            ]}
          />
        </div>

        <p>วันที่เริ่มใช้งาน:</p>
        <DatePicker
          format="DD/MM/YYYY"
          value={
            formData.used_date ? moment(new Date(formData.used_date)) : null
          }
          onChange={(date, dateString) =>
            setFormData((prevState) => ({
              ...prevState,
              used_date: date,
            }))
          }
          placeholder="วันที่เริ่มใช้งาน"
        />

        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={formData.note}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              note: e.target.value,
            }));
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EmployeeCreate;
