import React, { useState } from "react";
import { Button, Modal, Input, DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  uploadFileService,
  deleteFileService,
  updateTrainingService,
} from "../services/training";
import moment from "moment";

const TrainingView = ({ payload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(payload);

  const [loading, setLoading] = useState(false);
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image4, setImage4] = useState([]);
  const [image5, setImage5] = useState([]);
  const [fileContent, setFileContent] = useState([]);
  const [fileListMember, setFileListMember] = useState([]);

  const handleAddCheckpoint = async () => {
    setLoading(true);

    await updateTrainingService({
      ...formData,
      site_uuid: JSON.parse(localStorage.getItem("site")),
    });

    setLoading(false);
    window.location.reload();
  };

  const dummyContentRequest = ({ file, onSuccess }) => {
    setFileContent([file]);
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `content.${file.name.split(".")[1]}`;
    uploadFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file
    ).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        fileContent: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
    });
  };

  const dummyMemberRequest = ({ file, onSuccess }) => {
    setFileListMember([file]);
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `member.${file.name.split(".")[1]}`;
    uploadFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file
    ).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        fileListMember: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
    });
  };

  const onContentRemove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        fileContent: null,
      }));

      setFileContent([]);
    });
  };

  const onMemberRemove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        fileListMember: null,
      }));

      setFileListMember([]);
    });
  };

  const onImage1Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image1: null,
      }));

      setImage1([]);
    });
  };

  const onImage2Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image2: null,
      }));

      setImage2((prevState) =>
        prevState.filter((item) => item.uid !== file.uid)
      );
    });
  };

  const onImage3Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image3: null,
      }));

      setImage3((prevState) =>
        prevState.filter((item) => item.uid !== file.uid)
      );
    });
  };

  const onImage4Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image4: null,
      }));

      setImage4((prevState) =>
        prevState.filter((item) => item.uid !== file.uid)
      );
    });
  };

  const onImage5Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image5: null,
      }));

      setImage5((prevState) =>
        prevState.filter((item) => item.uid !== file.uid)
      );
    });
  };

  const dummyImage1Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `1.${file.name.split(".")[1]}`;
    uploadFileService(payload.id, site_uuid, file).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        image1: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
      setImage1([
        {
          uid: file.uid,
          name: file.newName,
          status: "done",
          url: URL.createObjectURL(file),
        },
      ]);
    });
  };

  const dummyImage2Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `2.${file.name.split(".")[1]}`;
    uploadFileService(payload.id, site_uuid, file).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        image2: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
      setImage2([
        {
          uid: file.uid,
          name: file.newName,
          status: "done",
          url: URL.createObjectURL(file),
        },
      ]);
    });
  };

  const dummyImage3Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `3.${file.name.split(".")[1]}`;
    uploadFileService(payload.id, site_uuid, file).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        image3: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
      setImage3((prevState) => [
        ...prevState,
        {
          uid: file.uid,
          name: file.newName,
          status: "done",
          url: URL.createObjectURL(file),
        },
      ]);
    });
  };

  const dummyImage4Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `4.${file.name.split(".")[1]}`;
    uploadFileService(payload.id, site_uuid, file).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        image4: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
      setImage4((prevState) => [
        ...prevState,
        {
          uid: file.uid,
          name: file.newName,
          status: "done",
          url: URL.createObjectURL(file),
        },
      ]);
    });
  };

  const dummyImage5Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `1.${file.name.split(".")[1]}`;
    uploadFileService(payload.id, site_uuid, file).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        image5: `docs/${site_uuid}/training/${payload.id}/${file.newName}`,
      }));
      setImage5((prevState) => [
        ...prevState,
        {
          uid: file.uid,
          name: file.newName,
          status: "done",
          url: URL.createObjectURL(file),
        },
      ]);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleAddCheckpoint();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        ดูข้อมูล
      </Button>
      <Modal
        title="หัวข้อการอบรม"
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        width={800}
      >
        <Button
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "#F5F5F5",
            borderColor: "#A4A2A2",
            color: "#000000",
          }}
        >
          ข้อมูลการอบรม
        </Button>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>หัวข้อการอบรม:</p>
            <Input
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder="หัวข้อการอบรม"
              className="w-[522px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "160px" }}>วันที่อบรม:</p>
            <DatePicker
              className="w-full"
              format="DD/MM/YYYY"
              value={
                formData.start_date
                  ? moment(new Date(formData.start_date))
                  : null
              }
              onChange={(date, dateString) =>
                setFormData((prevState) => ({
                  ...prevState,
                  start_date: date,
                }))
              }
              placeholder="วันที่เริ่มสัญญา"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>เวลาที่อบรม:</p>
            <Input
              value={formData.time}
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
              placeholder="เวลาที่อบรม"
              className="w-[200px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ชื่อผู้อบรม:</p>
            <Input
              value={formData.trainer}
              onChange={(e) =>
                setFormData({ ...formData, trainer: e.target.value })
              }
              placeholder="ชื่อผู้อบรม"
              className="w-[200px]"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ตำแหน่ง:</p>
            <Input
              value={formData.position}
              onChange={(e) =>
                setFormData({ ...formData, position: e.target.value })
              }
              placeholder="ตำแหน่ง"
              className="w-[200px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>วัตถุประสงค์:</p>
            <Input
              value={formData.objective}
              onChange={(e) =>
                setFormData({ ...formData, objective: e.target.value })
              }
              placeholder="วัตถุประสงค์"
              className="w-[522px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ขอบเขต:</p>
            <Input
              value={formData.scope}
              onChange={(e) =>
                setFormData({ ...formData, scope: e.target.value })
              }
              placeholder="ขอบเขตของหลักสูตร"
              className="w-[522px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>กลุ่มเป้าหมาย:</p>
            <Input
              value={formData.target}
              onChange={(e) =>
                setFormData({ ...formData, target: e.target.value })
              }
              placeholder="กลุ่มเป้าหมายผู้เข้าอบรม"
              className="w-[522px]"
            />
          </div>
        </div>
        <Button
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "#F5F5F5",
            borderColor: "#A4A2A2",
            color: "#000000",
          }}
        >
          เอกสารที่เกี่ยวข้อง
        </Button>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-2 items-center">
            <p style={{ width: "150px" }}>เนื้อหาหลักสูตรการอบรม:</p>
            <Upload
              customRequest={dummyContentRequest}
              disabled
              onRemove={onContentRemove}
              fileList={
                fileContent && fileContent.length === 0 && formData.fileContent
                  ? [
                      {
                        uid: "-1",
                        name: "content.pdf",
                        status: "done",
                        url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.fileContent}`,
                      },
                    ]
                  : fileContent
              }
              accept=".pdf"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2 items-center">
            <p style={{ width: "150px" }}>รายชื่อผู้เข้าร่วมอบรม:</p>
            <Upload
              customRequest={dummyMemberRequest}
              disabled
              onRemove={onMemberRemove}
              fileList={
                fileListMember &&
                fileListMember.length === 0 &&
                formData.fileListMember
                  ? [
                      {
                        uid: "-1",
                        name: "member.pdf",
                        status: "done",
                        url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.fileListMember}`,
                      },
                    ]
                  : fileListMember
              }
              accept=".pdf"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          <div className="flex flex-row gap-2 items-center">
            <p style={{ width: "150px" }}>รูปภาพการอบรม:</p>
            <div className="grid grid-cols-3 gap-4">
              <Upload
                customRequest={dummyImage1Request}
                listType="picture-card"
                disabled
                fileList={
                  image1 && image1.length === 0 && formData.image1
                    ? [
                        {
                          uid: "-1",
                          name: "1.jpg",
                          status: "done",
                          url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.image1}`,
                        },
                      ]
                    : image1
                }
                maxCount={1}
                accept="image/jpeg"
                onRemove={onImage1Remove}
              >
                {image1.length === 0 && !formData.image1 && "+ Upload"}
              </Upload>
              <Upload
                customRequest={dummyImage2Request}
                disabled
                listType="picture-card"
                fileList={
                  image2 && image2.length === 0 && formData.image2
                    ? [
                        {
                          uid: "-1",
                          name: "2.jpg",
                          status: "done",
                          url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${payload.image2}`,
                        },
                      ]
                    : image2
                }
                maxCount={1}
                accept="image/jpeg"
                onRemove={onImage2Remove}
              >
                {image2.length === 0 && !formData.image2 && "+ Upload"}
              </Upload>
              <Upload
                customRequest={dummyImage3Request}
                disabled
                listType="picture-card"
                fileList={
                  image3 && image3.length === 0 && formData.image3
                    ? [
                        {
                          uid: "-1",
                          name: "3.jpg",
                          status: "done",
                          url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${payload.image3}`,
                        },
                      ]
                    : image3
                }
                maxCount={1}
                accept="image/jpeg"
                onRemove={onImage3Remove}
              >
                {image3.length === 0 && !formData.image3 && "+ Upload"}
              </Upload>
              <Upload
                customRequest={dummyImage4Request}
                disabled
                listType="picture-card"
                fileList={
                  image4 && image4.length === 0 && formData.image4
                    ? [
                        {
                          uid: "-1",
                          name: "4.jpg",
                          status: "done",
                          url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${payload.image4}`,
                        },
                      ]
                    : image4
                }
                maxCount={1}
                accept="image/jpeg"
                onRemove={onImage4Remove}
              >
                {image4.length === 0 && !formData.image4 && "+ Upload"}
              </Upload>
              <Upload
                customRequest={dummyImage5Request}
                disabled
                listType="picture-card"
                fileList={
                  image5 && image5.length === 0 && formData.image5
                    ? [
                        {
                          uid: "-1",
                          name: "5.jpg",
                          status: "done",
                          url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${payload.image5}`,
                        },
                      ]
                    : image5
                }
                maxCount={1}
                accept="image/jpeg"
                onRemove={onImage5Remove}
              >
                {image5.length === 0 && !formData.image5 && "+ Upload"}
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default TrainingView;
