import { useState, useEffect } from "react";
import { Layout } from "antd";
import {
  FocusLayout,
  ControlBar,
  Chat,
  GridLayout,
  VideoConference,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { Track } from "livekit-client";
import axios from "axios";
import "./App.css";
const serverUrl = "wss://siam-ai-sz5aww73.livekit.cloud";
const { Content: AntdContent } = Layout;
const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (!localStorage.getItem("site")) return;
      axios
        .request({
          method: "POST",
          url: "https://meet-api.siamai.tech/getToken",
          data: {
            roomName: JSON.parse(localStorage.getItem("account")).account_uuid,
            participantName: JSON.parse(localStorage.getItem("account")).company_name,
            room_join: true,
          },
        })
        .then((res) => {
          setToken(res.data);
          //console.log(res.data);
        });
    };
    fetchToken();
  }, []);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      {token && (
        <LiveKitRoom
          video={true}
          audio={true}
          token={token}
          serverUrl={serverUrl}
          data-lk-theme="default"
          style={{ height: "80vh" }}
        >
          <VideoConference />
        </LiveKitRoom>
      )}
    </AntdContent>
  );
};

export default ContentComp;
