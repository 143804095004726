import React, { useState } from "react";
import { Button, Modal, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { setContractService } from "../services/contract";

const EmployeeCreate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    note: "",
  });

  const [fileList, setFileList] = useState([]);

  const dummyRequest = ({ file, onSuccess }) => {
    //console.log("file", file);
    setFileList([file]);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const [loading, setLoading] = useState(false);

  const handleAddCheckpoint = async () => {
    setLoading(true);

    await setContractService(
      {
        ...formData,
        site_uuid: JSON.parse(localStorage.getItem("site")),
      },
      fileList[0]
    );

    setLoading(false);
    window.location.reload();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddCheckpoint();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มเอกสาร
      </Button>
      <Modal
        title="เพิ่มเอกสารสัญญา"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อไพล์:</p>
        <Input
          value={formData.name}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              name: e.target.value,
            }));
          }}
          placeholder="ชื่อ"
        />
        <p>อัปโหลดไพล์ pdf:</p>
        <div className="flex items-center space-x-2">
          <Upload customRequest={dummyRequest} fileList={fileList} accept=".pdf">
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </div>

        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={formData.note}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              note: e.target.value,
            }));
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EmployeeCreate;
