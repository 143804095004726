import React, { useState, useEffect } from "react";
import { Layout, Table, Input, Button, Space } from "antd";
import {
  setConfigService,
  getConfigService,
  updateConfigService,
} from "../../services/config";
import { useSelector, useDispatch } from "react-redux";
import { setConfig } from "../../reducers/configSlice";

const { Content: AntdContent } = Layout;

const { Column } = Table;

const ContentComp = ({
  system,
  site_uuid,
  colorBgContainer,
  borderRadiusLG,
}) => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config.config);
  const [loading, setLoading] = useState(false);

  const handleConfigAdd = async () => {
    setLoading(true);

    await setConfigService(system, {
      site_uuid: site_uuid,
      template: config,
    });

    await updateConfigService(system, {
      site_uuid: site_uuid,
      template: config,
    });

    setLoading(false);
  };

  const handleAdd = () => {
    const newData = {
      key: (config.length + 1).toString(),
      destination: "",
      objective: ["", "", "", ""],
    };
    dispatch(setConfig([...config, newData]));
  };

  const handleDeleteChange = (key) => {
    const newData = config.filter((item) => item.key !== key);
    dispatch(setConfig(newData));
  };

  const handleConfigChange = async () => {
    setLoading(true);
    const data = await getConfigService(system, site_uuid);
    if (data.length === 0) {
      dispatch(setConfig([]));
    } else {
      dispatch(setConfig(data[0]?.template));
    }
    setLoading(false);
  };

  useEffect(() => {
    handleConfigChange();
  }, [site_uuid, system]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">ตั้งค่าปลายทาง - วัตถุประสงค์</div>
        <div className="space-x-2">
          <Button onClick={handleAdd} style={{ marginBottom: 16 }}>
            เพิ่มข้อมูล
          </Button>
          <Button
            onClick={handleConfigAdd}
            loading={loading}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            บันทึกข้อมูล
          </Button>
        </div>
      </div>
      <Table dataSource={config} pagination={false}>
        <Column
          title="ปลายทาง"
          dataIndex="destination"
          key="destination"
          render={(text, record) => (
            <Input
              value={text}
              onChange={(e) => {
                const newData = [...config];
                const index = newData.findIndex(
                  (item) => record.key === item.key
                );
                if (index > -1) {
                  const updatedItem = {
                    ...newData[index],
                    destination: e.target.value,
                  };
                  newData[index] = updatedItem;
                  dispatch(setConfig(newData));
                }
              }}
            />
          )}
        />
        <Column
          title="วัตถุประสงค์"
          dataIndex="objective"
          key="objective"
          render={(text, record) => (
            <Space size="middle">
              {text.map((action, index) => (
                <Input
                  key={`${record.key}-${index}`}
                  value={action}
                  onChange={(e) => {
                    const newData = config.map((item) => {
                      if (item.key === record.key) {
                        return {
                          ...item,
                          objective: [
                            ...item.objective.slice(0, index),
                            e.target.value,
                            ...item.objective.slice(index + 1),
                          ],
                        };
                      }
                      return item;
                    });
                    dispatch(setConfig(newData));
                  }}
                />
              ))}
            </Space>
          )}
        />

        <Column
          title="ตั้งค่า"
          dataIndex=""
          key="x"
          render={(_, record) => (
            <div>
              <Button
                onClick={() => handleDeleteChange(record.key)}
                type="primary"
                danger
              >
                ลบ
              </Button>
            </div>
          )}
        />
      </Table>
    </AntdContent>
  );
};

export default ContentComp;
