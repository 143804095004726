import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getEventService = async (account_uuid) => {
  const { data } = await supabase
    .from("account_noti_event")
    .select()
    .eq("account_uuid", account_uuid)
    .order("id", { ascending: false });
  return await data[0];
};

export const updateEventService = async (account_uuid) => {
  const { data } = await supabase
    .from("account_noti_event")
    .update({ event_status: true })
    .eq("account_uuid", account_uuid);
  return await data;
};
