import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import SecurityIcon from "../../SecurityIcon.png";

export default function Map({ mode, now }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(139.753);
  const [lat] = useState(35.6844);
  const [zoom] = useState(18);
  const [API_KEY] = useState("8PIF0OZrNYjkmzNeepvV");
  const [pointData, setPointData] = useState(null);

  useEffect(() => {
    if (now && now.length > 0) {
      setPointData(now?.map((item) => [item.long, item.lat]));
    }
  }, [now]);

  useEffect(() => {
    if (now && now.length === 0 && map.current) {
      map.current.remove();
      map.current = null;
    }
  }, [now]);

  useEffect(() => {
    if (pointData && pointData.length > 0) {
      if (!map.current) {
        map.current = new maplibregl.Map({
          container: mapContainer.current,
          style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
          center: [pointData[0][0], pointData[0][1]],
          zoom: zoom,
          pitch: 60,
        });
        map.current.addControl(new maplibregl.NavigationControl(), "top-right");
      }

      map.current.on("load", async () => {
        let image = await map.current.loadImage(SecurityIcon);
        pointData?.map(async (item, index) => {
          if (!map.current.getSource("device-" + index)) {
            await map.current.addImage("device-" + index, image.data);
            await map.current.addSource("device-" + index, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: item,
                    },
                  },
                ],
              },
            });
            await map.current.addLayer({
              id: "points-" + index,
              type: "symbol",
              source: "device-" + index,
              layout: {
                "icon-image": "device-" + index,
                "icon-size": 0.05,
              },
            });
          }
        });
      });

      pointData?.map(async (item, index) => {
        if (map.current.getSource("device-" + index)) {
          await map.current.flyTo({
            center: item,
            speed: 0.5,
          });
          await map.current.getSource("device-" + index).setData({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: item,
            },
          });
        }
      });
    }
  }, [API_KEY, lng, lat, zoom, pointData]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}
