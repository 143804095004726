import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Tag, Image } from "antd";
import moment from "moment";
import { Watermark } from "antd";
import { stampExitService } from "../../services/vms";

const CheckPointList = ({ title, data: initialData, destination }) => {
  const [open, setOpen] = useState(false);
  const [comfirm, setComfirm] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [destinationList, setDestinationList] = useState([]);
  const [data, setData] = useState(initialData);

  const Exit = async (vms_uuid) => {
    await stampExitService(vms_uuid);
    setData((prevData) =>
      prevData.filter((item) => item.vms_uuid !== vms_uuid)
    );
    setComfirm((prevComfirm) => ({ ...prevComfirm, [vms_uuid]: false }));
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (typeof destination === "object") {
      const newDestinationList = Object.keys(destination).map((key) => ({
        text: key,
        value: key,
      }));
      setDestinationList(newDestinationList);
    }
  }, [destination]);

  useEffect(() => {
    setCompanyName(
      JSON.parse(localStorage.getItem("account"))?.company_name ||
        JSON.parse(localStorage.getItem("account"))?.site_name
    );
  }, [open]);

  const columns = [
    {
      title: "เวลาเข้าพื้นที่",
      dataIndex: "enter_datetime",
      key: "enter_datetime",
      render: (_, { enter_datetime, enter_name }) => (
        <div>
          {enter_name} <br />{" "}
          {moment(new Date(enter_datetime)).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "จุดแสตมป์",
      dataIndex: "estamp_datetime",
      key: "estamp_datetime",
      render: (_, { estamp_datetime, estamp_name }) => (
        <div>
          {estamp_datetime ? (
            <>
              {estamp_name} <br />{" "}
              {moment(new Date(estamp_datetime)).format("DD/MM/YYYY HH:mm:ss")}{" "}
            </>
          ) : (
            <Tag color={"red"}>ไม่พบข้อมูล</Tag>
          )}
        </div>
      ),
    },
    {
      title: "เวลาออกพื้นที่",
      dataIndex: "exit_datetime",
      key: "exit_datetime",
      render: (_, { exit_datetime, exit_name }) => (
        <div>
          {exit_datetime ? (
            <>
              {exit_name} <br />{" "}
              {moment(new Date(exit_datetime)).format("DD/MM/YYYY HH:mm:ss")}{" "}
            </>
          ) : (
            <Tag color={"red"}>ยังอยู่ในพื้นที่</Tag>
          )}
        </div>
      ),
    },
    {
      title: "ระยะเวลา",
      dataIndex: "duration",
      key: "duration",
      render: (_, { enter_datetime, exit_datetime }) => (
        <div>
          {exit_datetime ? (
            (() => {
              const duration = moment.duration(
                moment(exit_datetime).diff(moment(enter_datetime))
              );
              const hours = duration.hours();
              const minutes = duration.minutes();
              if (hours === 0 && minutes === 0) return "น้อยกว่า 1 นาที";
              if (hours === 0) return `${minutes} นาที`;
              return `${hours} ชั่วโมง ${minutes} นาที`;
            })()
          ) : (
            <Tag color={"red"}>ยังอยู่ในพื้นที่</Tag>
          )}
        </div>
      ),
    },
    {
      title: "ประเภทผู้ติดต่อ",
      dataIndex: "VMSID",
      key: "VMSID",
    },
    {
      title: "ติดต่อแผนก",
      dataIndex: "destination",
      key: "destination",
      filters: destinationList,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.destination.indexOf(value) === 0,
    },
    {
      title: "วัตถุประสงค์",
      dataIndex: "objective",
      key: "objective",
    },
    {
      title: "ข้อมูลผู้มาติตต่อ",
      dataIndex: "name",
      key: "name",
      render: (_, { metadata, licensePlate, phone, typeCar, company }) => (
        <>
          <div>บริษัท: {company}</div>
          <div>ชื่อ: {metadata?.name}</div>
          <div>เลขบัตร: {metadata?.id}</div>
          <div>เบอร์ติดต่อ: {phone}</div>
          <div>ทะเบียนรถ: {licensePlate}</div>
          <div>ประเภทรถ: {typeCar}</div>
          <div>ที่อยู่: {metadata?.address}</div>
        </>
      ),
    },
    {
      title: "ภาพถ่าย",
      dataIndex: "img",
      key: "img",
      render: (_, { img, metadata }) => {
        return (
          <div>
            <Image width={100} src={metadata?.image ? metadata?.image : img} />
          </div>
        );
      },
    },
    {
      title: "สแตมป์ขาออก",
      dataIndex: "exit",
      hidden: title === "จำนวนผู้ติดต่อที่ยังไม่ออกพื้นที่ (คน)" ? false : true,
      key: "exit",
      render: (_, { vms_uuid, metadata }) => {
        return (
          <div>
            <Modal
              title="ยืนยันการออกพื้นที่"
              open={comfirm[vms_uuid]}
              onOk={() => Exit(vms_uuid)}
              okButtonProps={{
                type: "primary",
                danger: true,
                children: "ยืนยันการออกพื้นที่",
              }}
              onCancel={() => setComfirm({ [vms_uuid]: false })}
            >
              <p>ยืนยันการออกพื้นที่ {metadata?.name}</p>
            </Modal>
            <Button
              onClick={() => setComfirm({ [vms_uuid]: true })}
              type="primary"
              danger
            >
              แสตมป์ออกพื้นที่
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <a onClick={() => setOpen(true)}>ดูรายละเอียด</a>

      <Modal
        title={title}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1300}
      >
        <Watermark
          content={["วัตถุประสงค์เพื่อรักษาความปลอดภัย", companyName]}
          font={{
            color: "rgba(255, 0, 0, 0.15)",
          }}
        >
          <Table rowKey="id" columns={columns} dataSource={data} />
        </Watermark>
      </Modal>
    </div>
  );
};

export default CheckPointList;
