import axios from "axios";

const api = axios.create({
  baseURL: "https://vms-api.siamai.workers.dev/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 3000,
});

async function postProgram(data) {
  //console.log("Posting data:", data);
  try {
    const response = await api.post("enter/program", data);
    //console.log("Response:", response.data);
    return { res: response.data, error: false };
  } catch (error) {
    console.error("Error posting data:", error);
    return { res: error, error: true };
  }
}

async function postDashboard(data) {
  //console.log("Posting data:", data);
  try {
    const response = await api.post("monitor", data);
    //console.log("Response:", response.data);
    return { res: response.data, error: false };
  } catch (error) {
    console.error("Error posting data:", error);
    return { res: error, error: true };
  }
}

export { postProgram, postDashboard };
