import React, { useState, useEffect } from "react";
import { Button, Modal, Input, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { updateSiteService, getSiteService } from "../services/site";
import { setSiteAccount } from "../reducers/siteSlice";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import moment from "moment";

const EditCreate = ({ payload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    site_name: "",
    site_name_en: "",
    updated_at: "",
    service_area_manager: { name: "", phone: "" },
    job_type: "",
    customer_group: "",
    business_type: "",
    company_address: "",
    site_address: "",
    employer_name: "",
    employer_phone: "",
    employer_email: "",
    site_phone: "",
    site_fax: "",
    contract_start_date: "",
    contract_end_date: "",
    site_username: "",
    site_password: "",
    site_note: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    delete payload.account;
    delete payload.accountLimit;
    setFormData(payload);
  }, [payload]);

  const duration = (start, end) => {
    const startDate = moment(new Date(start)).startOf("day");
    const endDate = moment(new Date(end)).startOf("day");

    const duration = moment.duration(endDate.diff(startDate));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    if (years > 0 && months > 0 && days > 0) {
      return `${years} ปี ${months} เดือน ${days} วัน`;
    } else if (months > 0 && days > 0) {
      return `${months} เดือน ${days} วัน`;
    } else if (years > 0 && days > 0) {
      return `${years} ปี ${days} วัน`;
    } else if (years > 0 && months > 0) {
      return `${years} ปี ${months} เดือน`;
    } else if (years > 0) {
      return `${years} ปี`;
    } else if (months > 0) {
      return `${months} เดือน`;
    } else if (days > 0) {
      return `${days} วัน`;
    }
  };

  const handleUpdateSite = async () => {
    setLoading(true);
    formData.updated_at = new Date();
    await updateSiteService({
      ...formData,
    });
    const data = await getSiteService(payload.account_uuid);
    dispatch(setSiteAccount(data));

    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleUpdateSite();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("service_area_manager")) {
      const key = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        service_area_manager: {
          ...prevState.service_area_manager,
          [key]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

    if (regex.test(value) || value === "") {
      setFormData((prevState) => ({ ...prevState, site_username: value }));
    } else {
      messageApi.open({
        type: "error",
        content: "ชื่อผู้ใช้ต้องประกอบด้วย A-Z, a-z, 0-9",
      });
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

    if (regex.test(value) || value === "") {
      setFormData((prevState) => ({ ...prevState, site_password: value }));
    } else {
      messageApi.open({
        type: "error",
        content:
          "ชื่อผู้ใช้ต้องประกอบด้วย A-Z, a-z, 0-9, !@#$%^&*()_+-=[]{};:'|,.<>/?",
      });
    }
  };

  return (
    <>
      <Button loading={loading} onClick={showModal}>
        แก้ไข
      </Button>
      <Modal
        title="แก้ไขบัญชีหน่วยงาน"
        open={isModalOpen}
        okText="แก้ไข"
        cancelText="ยกเลิก"
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
      >
        <Button
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "#F5F5F5",
            borderColor: "#A4A2A2",
            color: "#000000",
          }}
        >
          ข้อมูลบริษัท
        </Button>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>ผู้รับผิดชอบพื้นที่บริการ:</p>
            <Input
              name="service_area_manager.name"
              value={formData.service_area_manager?.name}
              onChange={handleInputChange}
              placeholder="ผู้รับผิดชอบพื้นที่บริการ"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "190px" }}>เบอร์ผู้รับผิดชอบ:</p>
            <Input
              name="service_area_manager.phone"
              value={formData.service_area_manager?.phone}
              onChange={handleInputChange}
              placeholder="เบอร์ผู้รับผิดชอบ"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>แก้ไขวันที่:</p>
            <Input
              name="updated_at"
              disabled
              value={
                formData.updated_at
                  ? moment(formData.updated_at).format("DD/MM/YYYY HH:mm:ss")
                  : moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
              }
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-8">
          <div className="flex flex-row gap-2">
            <p style={{ width: "120px" }}>ประเภทงาน:</p>
            <Input
              name="job_type"
              value={formData.job_type}
              onChange={handleInputChange}
              placeholder="ประเภทงาน"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "120px" }}>กลุ่มลูกค้า:</p>
            <Input
              name="customer_group"
              value={formData.customer_group}
              onChange={handleInputChange}
              placeholder="กลุ่มลูกค้า"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "100px" }}>ประเภทธุรกิจ:</p>
            <Input
              name="business_type"
              className="w-[300px]"
              value={formData.business_type}
              onChange={handleInputChange}
              placeholder="ประเภทธุรกิจ"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-8">
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>ชื่อหน่วยงาน (TH):</p>
            <Input
              name="site_name"
              value={formData.site_name}
              onChange={handleInputChange}
              placeholder="ชื่อหน่วยงาน (TH)"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>ชื่อหน่วยงาน (ENG):</p>
            <Input
              name="site_name_en"
              value={formData.site_name_en}
              onChange={handleInputChange}
              placeholder="ชื่อหน่วยงาน (ENG)"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>ที่อยู่บริษัท:</p>
            <Input
              name="company_address"
              value={formData.company_address}
              onChange={handleInputChange}
              placeholder="ที่อยู่บริษัท"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>ที่อยู่ / สถานที่ปฎิบัติงาน:</p>
            <Input
              name="site_address"
              value={formData.site_address}
              onChange={handleInputChange}
              placeholder="ที่อยู่ / สถานที่ปฎิบัติงาน"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-4">
          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>ชื่อผู้ว่าจ้างหรือตัวแทน:</p>
            <Input
              name="employer_name"
              value={formData.employer_name}
              onChange={handleInputChange}
              placeholder="ชื่อผู้ว่าจ้างหรือตัวแทน"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>โทรศัพท์มือถือ:</p>
            <Input
              name="employer_phone"
              value={formData.employer_phone}
              onChange={handleInputChange}
              placeholder="โทรศัพท์มือถือ"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-4">
          <div className="flex flex-row gap-2">
            <p style={{ width: "120px" }}>โทรศัพท์:</p>
            <Input
              name="site_phone"
              value={formData.site_phone}
              onChange={handleInputChange}
              placeholder="โทรศัพท์"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "120px" }}>โทรสาร:</p>
            <Input
              name="site_fax"
              value={formData.site_fax}
              onChange={handleInputChange}
              placeholder="โทรสาร"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "120px" }}>E-Mail:</p>
            <Input
              name="employer_email"
              value={formData.employer_email}
              onChange={handleInputChange}
              placeholder="E-Mail"
            />
          </div>
        </div>
        <Button
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "#F5F5F5",
            borderColor: "#A4A2A2",
            color: "#000000",
          }}
        >
          รายละเอียดสัญญาข้อตกลง
        </Button>

        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-2">
            <p style={{ width: "160px" }}>วันที่เริ่มสัญญา:</p>
            <DatePicker
              name="contract_start_date"
              className="w-full"
              format="DD/MM/YYYY"
              value={
                formData.contract_start_date
                  ? moment(new Date(formData.contract_start_date))
                  : null
              }
              onChange={(date, dateString) =>
                setFormData((prevState) => ({
                  ...prevState,
                  contract_start_date: date,
                }))
              }
              placeholder="วันที่เริ่มสัญญา"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>วันที่สิ้นสุดสัญญา:</p>
            <DatePicker
              name="contract_end_date"
              className="w-full"
              format="DD/MM/YYYY"
              value={
                formData.contract_end_date
                  ? moment(new Date(formData.contract_end_date))
                  : null
              }
              onChange={(date, dateString) =>
                setFormData((prevState) => ({
                  ...prevState,
                  contract_end_date: date,
                }))
              }
              placeholder="วันที่สิ้นสุดสัญญา"
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "180px" }}>ระยะเวลาสัญญา:</p>
            <Input
              name="contract_duration"
              disabled
              value={
                formData.contract_start_date && formData.contract_end_date
                  ? duration(
                      formData.contract_start_date,
                      formData.contract_end_date
                    )
                  : ""
              }
              onChange={handleInputChange}
            />
          </div>
        </div>

        <Button
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
            backgroundColor: "#F5F5F5",
            borderColor: "#A4A2A2",
            color: "#000000",
          }}
        >
          ข้อมูลเข้าใช้งาน
        </Button>
        <p>ชื่อผู้ใช้:</p>
        <Input
          name="site_username"
          value={formData.site_username}
          onChange={handleUsernameChange}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="username"
        />
        <p>รหัสผ่าน:</p>
        <Input.Password
          name="site_password"
          value={formData.site_password}
          onChange={handlePasswordChange}
          prefix={<KeyOutlined className="site-form-item-icon" />}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          placeholder="password"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          name="site_note"
          value={formData.site_note}
          onChange={handleInputChange}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EditCreate;
