import React, { useState, useEffect } from "react";
import { Layout, Select, Image, Watermark, Table, Tag } from "antd";
import CardMonitor from "../../components/dashboard/CardMonitor";
import { FileProtectOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import MapDemo from "../../components/dashboard/MapDemoo";
import { getGeoService } from "../../services/geolocation";
import { getLogs } from "../../services/daily";
import moment from "moment";
// import Model from "../../components/3d";
import Download from "../../components/Download";

const { Content: AntdContent } = Layout;

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const formatter = (value) => <CountUp end={value} separator="," />;
  const [geo, setGeo] = useState([]);
  const [mode, setMode] = useState("1");
  const [companyName, setCompanyName] = useState("");
  const [logs, setLogs] = useState([]);

  const columns = [
    {
      title: "รูปภาพ",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image
          src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${image}`}
          width={100}
          height={100}
        />
      ),
    },
    {
      title: "รายงาน",
      dataIndex: "meta",
      key: "meta",
      render: (_, { meta }) => (
        <div>
          {meta.split("\n").map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}
        </div>
      ),
    },
    {
      title: "ผู้รายงาน",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "เวลา",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => (
        <div>
          {moment(created_at.replace("+00:00", "")).format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const result = await getLogs(JSON.parse(localStorage.getItem("site")));
      setLogs(result);
    };
    fetchData();
    setInterval(() => {
      fetchData();
    }, 5 * 1000);
  }, [localStorage.getItem("site")]);

  useEffect(() => {
    setCompanyName(
      JSON.parse(localStorage.getItem("account"))?.company_name ||
        JSON.parse(localStorage.getItem("account"))?.site_name
    );
    const fetchData = async () => {
      const result = await getGeoService(
        JSON.parse(localStorage.getItem("site"))
      );
      if (result.length === 0) {
        setGeo([]);
      } else {
        setGeo(result);
      }
    };
    fetchData();
    setInterval(() => {
      fetchData();
    }, 15 * 1000);
  }, [localStorage.getItem("site")]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      {/* <div className="w-[100vw] h-[50vh] grid grid-cols-1 gap-2">
      <Model />
     </div> */}
      <div className="mt-4 grid grid-cols-2 gap-2">
        <CardMonitor
          color={"green"}
          icon={<FileProtectOutlined />}
          title={
            <div className="flex justify-between">
              <div>รายงานประจำวัน</div>
              <div className="w-8 h-8 flex items-center justify-center rounded-[6px] text-black text-[24px]">
                <Download
                  tip={"โหลดรายงานประจำวัน"}
                  icon={<CloudDownloadOutlined />}
                  status={"daily"}
                />
              </div>
            </div>
          }
          actions={
            <div className="overflow-y-scroll max-h-[410px] w-full space-y-2 text-left">
              <Watermark
                content={["วัตถุประสงค์เพื่อรักษาความปลอดภัย", companyName]}
                font={{
                  color: "rgba(255, 0, 0, 0.15)",
                }}
              >
                <Table rowKey="id" columns={columns} dataSource={logs} />
              </Watermark>
            </div>
          }
        />
        <CardMonitor
          className="col-span-2"
          title={
            <div className="flex justify-between">
              <div>ตำแหน่งการใช้งาน</div>
              <div>
                <Select
                  className="ml-4"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="ตำแหน่งล่าสุด"
                  onChange={(value) => setMode(value)}
                  value={mode}
                  options={[{ value: "1", label: "ตำแหน่งล่าสุด" }]}
                />
              </div>
            </div>
          }
          actions={
            <div className="w-[100vw]">
              <MapDemo mode={mode} now={geo} />
            </div>
          }
        />
      </div>
    </AntdContent>
  );
};

export default ContentComp;
