import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const signInWithEmail = async (email, password) => {
  const { data } = await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });
  //console.log(data);
  return data;
};

export const signInWithUsername = async (username, password) => {
  const { data } = await supabase
    .from("account_site")
    .select()
    .eq("site_username", username)
    .eq("site_password", password);
  return data;
};
