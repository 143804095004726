import React, { useState, useEffect } from "react";
import { Layout, Input, Button } from "antd";
import {
  UserOutlined,
  FormOutlined,
  ToolOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  FolderOpenOutlined,
  SolutionOutlined,
  FileSearchOutlined,
  CarOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { getSiteByUuidService } from "../../services/site";
import { getEmployeeByUuidService } from "../../services/employee";
import { getStockByUuidService } from "../../services/stock";
import moment from "moment";

const { Content: AntdContent } = Layout;

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const [site, setSite] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [stock, setStock] = useState([]);

  useEffect(() => {
    const fetchSite = async () => {
      const data = await getSiteByUuidService(
        JSON.parse(localStorage.getItem("site"))
      );
      setSite(data);
    };
    const fetchEmployee = async () => {
      const data = await getEmployeeByUuidService(
        JSON.parse(localStorage.getItem("site"))
      );
      setEmployee(data);
    };

    const fetchStock = async () => {
      const data = await getStockByUuidService(
        JSON.parse(localStorage.getItem("site"))
      );
      setStock(data);
    };

    fetchStock();
    fetchSite();
    fetchEmployee();
  }, [localStorage.getItem("site")]);

  return (
    <>
      <AntdContent
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 360,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <div className="text-2xl font-bold">Centralized Security Platform</div>
        <div className="flex flex-col gap-4 mt-2 w-[500px]">
          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>ชื่อหน่วยงาน/บริษัท (TH):</p>
            <Input
              disabled
              value={site[0]?.site_name}
              style={{
                color: "#000000",
              }}
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>ชื่อหน่วยงาน/บริษัท (EN):</p>
            <Input
              disabled
              value={site[0]?.site_name_en}
              style={{
                color: "#000000",
              }}
            />
          </div>

          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>ผู้รับผิดชอบพื้นที่บริการ:</p>
            <Input
              disabled
              value={site[0]?.service_area_manager?.name}
              style={{
                color: "#000000",
              }}
            />
          </div>

          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>เบอร์โทรศัพท์ผู้รับผิดชอบ:</p>
            <Input
              disabled
              value={site[0]?.service_area_manager?.phone}
              style={{
                color: "#000000",
              }}
            />
          </div>

          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>วันที่เริ่มสัญญา:</p>
            <Input
              disabled
              value={moment(new Date(site[0]?.contract_start_date)).format(
                "DD/MM/YYYY"
              )}
              style={{
                color: "#000000",
              }}
            />
          </div>
          <div className="flex flex-row gap-2">
            <p style={{ width: "250px" }}>วันที่สิ้นสุดสัญญา:</p>
            <Input
              disabled
              value={moment(new Date(site[0]?.contract_end_date)).format(
                "DD/MM/YYYY"
              )}
              style={{
                color: "#000000",
              }}
            />
          </div>
        </div>
      </AntdContent>
      <div className="flex items-center justify-center">
        <div className="grid grid-cols-7 gap-4">
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <UserOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => window.open(`/docs/employee`)}
              >
                พนักงาน ({employee.length})
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <FormOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 3);
                  window.open(`/`);
                }}
              >
                บันทึกการเข้า - ออกงาน
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <ToolOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => window.open(`/docs/stock`)}
              >
                รายการอุปกรณ์ ({stock.length})
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <FileDoneOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => window.open(`/docs/contract`)}
              >
                เอกสารสัญญา
              </Button>
            </div>
          </div>

          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <FileSyncOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => window.open(`/docs/sow`)}
              >
                ขอบเขตการทำงาน
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <FolderOpenOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 4);
                  window.open(`/`);
                }}
              >
                รายงานประจำวัน
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <SolutionOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => window.open(`/docs/training`)}
              >
                การอบรมพนักงาน
              </Button>
            </div>
          </div>

          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <FileSearchOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 2);
                  window.open(`/`);
                }}
              >
                Guard Tour
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <CarOutlined className="text-[64px]" />
              <Button
                type="primary"
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 1);
                  window.open(`/`);
                }}
              >
                Visitor (VMS)
              </Button>
            </div>
          </div>

          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <BookOutlined className="text-[64px]" />
              <Button
                type="primary"
                disabled
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 1);
                  window.open(`/`);
                }}
              >
                คู่มือความปลอดภัย
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <BookOutlined className="text-[64px]" />
              <Button
                type="primary"
                disabled
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 1);
                  window.open(`/`);
                }}
              >
                ตัวชี้วัด (KPI)
              </Button>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg w-[180px] h-[150px]">
            <div className="flex flex-col items-center justify-center gap-2 p-4">
              <BookOutlined className="text-[64px]" />
              <Button
                type="primary"
                disabled
                className="mt-4"
                onClick={() => {
                  localStorage.setItem("system", 1);
                  window.open(`/`);
                }}
              >
                การประเมินความเสี่ยง
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentComp;
