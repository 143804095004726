import React, { useState } from "react";
import { Button, Modal, Input, DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  updateEmployeeService,
  uploadFileService,
  deleteFileService,
} from "../services/employee";
import moment from "moment";
import { detectFace } from "../services/face";

const EmployeeEdit = ({ payload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(payload);
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);

  const [fileListDoc1, setFileListDoc1] = useState([]);
  const [fileListDoc2, setFileListDoc2] = useState([]);
  const [fileListDoc3, setFileListDoc3] = useState([]);

  const [loading, setLoading] = useState(false);

  const dummyDoc1Request = ({ file, onSuccess }) => {
    setFileListDoc1([file]);
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `doc1.${file.name.split(".")[1]}`;
    uploadFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file
    ).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        doc1: `docs/${site_uuid}/employee/${payload.id}/${file.newName}`,
      }));
    });
  };

  const dummyDoc2Request = ({ file, onSuccess }) => {
    setFileListDoc2([file]);
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `doc2.${file.name.split(".")[1]}`;
    uploadFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file
    ).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        doc2: `docs/${site_uuid}/employee/${payload.id}/${file.newName}`,
      }));
    });
  };

  const dummyDoc3Request = ({ file, onSuccess }) => {
    setFileListDoc3([file]);
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `doc3.${file.name.split(".")[1]}`;
    uploadFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file
    ).then((res) => {
      onSuccess("ok");
      setFormData((prevState) => ({
        ...prevState,
        doc3: `docs/${site_uuid}/employee/${payload.id}/${file.newName}`,
      }));
    });
  };

  const onDoc1Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        doc1: null,
      }));

      setFileListDoc1([]);
    });
  };

  const onDoc2Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        doc2: null,
      }));
      setFileListDoc2([]);
    });
  };

  const onDoc3Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        doc3: null,
      }));
      setFileListDoc3([]);
    });
  };

  const onImage1Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image1: null,
        vector1: null,
      }));

      setImage1([]);
    });
  };

  const onImage2Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image2: null,
        vector2: null,
      }));

      setImage2((prevState) =>
        prevState.filter((item) => item.uid !== file.uid)
      );
    });
  };

  const onImage3Remove = (file) => {
    deleteFileService(
      payload.id,
      JSON.parse(localStorage.getItem("site")),
      file.name
    ).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        image3: null,
        vector3: null,
      }));

      setImage3((prevState) =>
        prevState.filter((item) => item.uid !== file.uid)
      );
    });
  };

  const dummyImage1Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `1.${file.name.split(".")[1]}`;
    detectFace(file).then((data) => {
      if (data) {
        uploadFileService(payload.id, site_uuid, file).then((res) => {
          onSuccess("ok");
          setFormData((prevState) => ({
            ...prevState,
            image1: `docs/${site_uuid}/employee/${payload.id}/${file.newName}`,
            vector1: data["data"]["data"][0]["embedding"],
          }));
          setImage1([
            {
              uid: file.uid,
              name: file.newName,
              status: "done",
              url: URL.createObjectURL(file),
            },
          ]);
        });
      }
    });
  };

  const dummyImage2Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `2.${file.name.split(".")[1]}`;
    detectFace(file).then((data) => {
      if (data) {
        uploadFileService(payload.id, site_uuid, file).then((res) => {
          onSuccess("ok");
          setFormData((prevState) => ({
            ...prevState,
            image2: `docs/${site_uuid}/employee/${payload.id}/${file.newName}`,
            vector2: data["data"]["data"][0]["embedding"],
          }));
          setImage2([
            {
              uid: file.uid,
              name: file.newName,
              status: "done",
              url: URL.createObjectURL(file),
            },
          ]);
        });
      }
    });
  };

  const dummyImage3Request = ({ file, onSuccess }) => {
    const site_uuid = JSON.parse(localStorage.getItem("site"));
    file.newName = `3.${file.name.split(".")[1]}`;
    detectFace(file).then((data) => {
      if (data) {
        uploadFileService(payload.id, site_uuid, file).then((res) => {
          onSuccess("ok");
          setFormData((prevState) => ({
            ...prevState,
            image3: `docs/${site_uuid}/employee/${payload.id}/${file.newName}`,
            vector3: data["data"]["data"][0]["embedding"],
          }));
          setImage3([
            {
              uid: file.uid,
              name: file.newName,
              status: "done",
              url: URL.createObjectURL(file),
            },
          ]);
        });
      }
    });
  };

  const handleAddCheckpoint = async () => {
    setLoading(true);

    await updateEmployeeService(formData);

    setLoading(false);
    window.location.reload();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddCheckpoint();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        ดูข้อมูล
      </Button>
      <Modal
        title="แก้ไขรายชื่อพนักงาน"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="แก้ไข"
      >
        <p>ชื่อ:</p>
        <Input
          value={formData.name}
          disabled
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              name: e.target.value,
            }));
          }}
          placeholder="ชื่อ"
        />
        <p>เลขบัตรประชาชน:</p>
        <div className="flex items-center space-x-2">
          <Input
            value={formData.idcard}
            disabled
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                idcard: e.target.value,
              }));
            }}
            placeholder="เลขบัตรประชาชน"
          />
        </div>

        <p>ภาพใบหน้า:</p>
        <div className="flex items-center justify-center">
          <div className="grid grid-cols-3 gap-4">
            <Upload
              customRequest={dummyImage1Request}
              disabled
              listType="picture-card"
              fileList={
                image1 && image1.length === 0 && formData.image1
                  ? [
                      {
                        uid: "-1",
                        name: "1.jpg",
                        status: "done",
                        url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.image1}`,
                      },
                    ]
                  : image1
              }
              maxCount={1}
              accept="image/jpeg"
              onRemove={onImage1Remove}
            >
              {image1.length === 0 && !formData.image1 && "ภาพหน้าตรง"}
            </Upload>
            <Upload
              customRequest={dummyImage2Request}
              disabled
              listType="picture-card"
              fileList={
                image2 && image2.length === 0 && formData.image2
                  ? [
                      {
                        uid: "-1",
                        name: "2.jpg",
                        status: "done",
                        url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.image2}`,
                      },
                    ]
                  : image2
              }
              maxCount={1}
              accept="image/jpeg"
              onRemove={onImage2Remove}
            >
              {image2.length === 0 && !formData.image2 && "ภาพหน้ายิ้ม"}
            </Upload>

            <Upload
              customRequest={dummyImage3Request}
              disabled
              listType="picture-card"
              fileList={
                image3 && image3.length === 0 && formData.image3
                  ? [
                      {
                        uid: "-1",
                        name: "3.jpg",
                        status: "done",
                        url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.image3}`,
                      },
                    ]
                  : image3
              }
              maxCount={1}
              accept="image/jpeg"
              onRemove={onImage3Remove}
            >
              {image3.length === 0 && !formData.image3 && "ภาพหน้าอ้าปาก"}
            </Upload>
          </div>
        </div>

        <p>เบอร์ติดต่อ:</p>
        <div className="flex items-center space-x-2">
          <Input
            value={formData.phone}
            disabled
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                phone: e.target.value,
              }));
            }}
            placeholder="เบอร์ติดต่อ"
          />
        </div>

        <p>วัน/เดือน/ปีเกิด :</p>
        <DatePicker
          format="DD/MM/YYYY"
          disabled
          value={formData.birthday ? moment(new Date(formData.birthday)) : null}
          onChange={(date, dateString) =>
            setFormData((prevState) => ({
              ...prevState,
              birthday: date,
            }))
          }
          placeholder="วัน/เดือน/ปีเกิด"
        />

        <p>ใบอนุญาตเลขที่ :</p>
        <Input
          value={formData.license}
          disabled
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              license: e.target.value,
            }));
          }}
          placeholder="ใบอนุญาตเลขที่ :"
        />

        <p>เอกสาร ธภ.6:</p>
        <Upload
          customRequest={dummyDoc1Request}
          disabled
          onRemove={onDoc1Remove}
          fileList={
            fileListDoc1 && fileListDoc1.length === 0 && formData.doc1
              ? [
                  {
                    uid: "-1",
                    name: "doc1.pdf",
                    status: "done",
                    url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.doc1}`,
                  },
                ]
              : fileListDoc1
          }
          accept=".pdf"
        >
          {fileListDoc1.length === 0 && !formData.doc1 && (
            <Button icon={<UploadOutlined />}>Upload</Button>
          )}
        </Upload>

        <p>เอกสาร ธภ.7:</p>
        <Upload
          customRequest={dummyDoc2Request}
          disabled
          onRemove={onDoc2Remove}
          fileList={
            fileListDoc2 && fileListDoc2.length === 0 && formData.doc2
              ? [
                  {
                    uid: "-1",
                    name: "doc2.pdf",
                    status: "done",
                    url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.doc2}`,
                  },
                ]
              : fileListDoc2
          }
          accept=".pdf"
        >
          {fileListDoc2.length === 0 && !formData.doc2 && (
            <Button icon={<UploadOutlined />}>Upload</Button>
          )}
        </Upload>

        <p>เอกสาร ใบรับรองแพทย์:</p>
        <Upload
          customRequest={dummyDoc3Request}
          disabled
          onRemove={onDoc3Remove}
          fileList={
            fileListDoc3 && fileListDoc3.length === 0 && formData.doc3
              ? [
                  {
                    uid: "-1",
                    name: "doc3.pdf",
                    status: "done",
                    url: `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/${formData.doc3}`,
                  },
                ]
              : fileListDoc3
          }
          accept=".pdf"
        >
          {fileListDoc3.length === 0 && !formData.doc3 && (
            <Button icon={<UploadOutlined />}>Upload</Button>
          )}
        </Upload>

        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={formData.note}
          disabled
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              note: e.target.value,
            }));
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EmployeeEdit;
